import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

export type IrrelevantGroupCategoryMap = {
    [auditTemplateCategoryId: string]: string[]; // array of subGroup ids
};

export default function useIrrelevantGroupCategoryMap(/*{ groupId }: { groupId: string }*/) {
    const [irrelevantGroupCategoryMap, setIrrelevantGroupCategoryMap] =
        useState<IrrelevantGroupCategoryMap>({
            // Perkolat-tank/bassin
            "RGS_19657e7c-7973-494d-95c5-152bdc109891": ["6EV3Qs8PolVEfzQ5T0eL"],
            // Tæthedsprøvning
            "RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291": [
                "nbdNBPjtBm5Hj1kWk9Xp",
                "1YV7fqKyt4NwijucQpJC",
                "6EV3Qs8PolVEfzQ5T0eL",
                "U3VGEINSY3gjApLETeQm",
                "S7BLcCZhBxdkAR6NqpUb",
                "Yj4WPjA0NOibSNGI5cxY",
                "mNckGHhF49pMTiDiYbiV",
                "bnH82AqUx9tm0VT4cziD",
            ],
            // Perkolat analyser
            "RGS_554528db-3333-441a-b6c0-bad17557d370": [
                "eKzZLZGb0v7z8Uiswy0G",
                "U3VGEINSY3gjApLETeQm",
            ],
            // Alamer
            "RGS_5c05b284-5573-4f40-9c74-84939a6a20b4": [
                "nbdNBPjtBm5Hj1kWk9Xp",
                "PgEvJdecIDNogc98GowX",
                "RFEH9zFya2Yd3HEvy7b4",
                "1YV7fqKyt4NwijucQpJC",
                "bnH82AqUx9tm0VT4cziD",
                "RLSZUWIwDOL3DjCecZbx",
                "zwAOxiKMeOjjnaR4ryUR",
            ],
            // Førstehjælpsudstyr
            "RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
            ],
            // Pumper
            "RGS_808d208a-8799-4979-ab01-a7d2d2470672": [
                "G4tlOF3QBqPyOcrmlXxK",
                "eKzZLZGb0v7z8Uiswy0G",
                "bnH82AqUx9tm0VT4cziD",
            ],
            // Brønde, drænbrønde og sandfang
            "RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6": ["1YV7fqKyt4NwijucQpJC"],
            // Løftegrej
            "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a": [
                "U3VGEINSY3gjApLETeQm",
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
                "Yj4WPjA0NOibSNGI5cxY",
            ],
            // Olietanke
            "RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
            ],
            // Maskiner
            "RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
                "8JV4Kh29CBcpEGAApeVQ",
            ],
            // Porte
            "RGS_bacb90dc-7e4a-4277-b566-591e55bb4844": [
                "S7BLcCZhBxdkAR6NqpUb",
                "Yj4WPjA0NOibSNGI5cxY",
                "zwAOxiKMeOjjnaR4ryUR",
            ],
            // Haller
            "RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
                "8JV4Kh29CBcpEGAApeVQ",
                "U3VGEINSY3gjApLETeQm",
            ],
            // Betonelementer
            "RGS_cdf5e221-aa55-452a-b640-319584a41eae": ["S7BLcCZhBxdkAR6NqpUb"],
            // Elværktøj
            "RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
            ],
            // Kemikalier
            "RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
            ],
            // Kompressor
            "RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
                "RLSZUWIwDOL3DjCecZbx",
            ],
            // Brandudstyr
            "RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5": [
                "S7BLcCZhBxdkAR6NqpUb",
                "bnH82AqUx9tm0VT4cziD",
            ],
            // Beredskabsplan
            "RGS_39efaf42-bfce-4d02-973b-822c962a8fe6": [
                "S7BLcCZhBxdkAR6NqpUb",
                "zwAOxiKMeOjjnaR4ryUR",
            ],
            // Stiger
            "RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4": ["Yj4WPjA0NOibSNGI5cxY"],
        });

    // useEffect(() => {
    //     console.log(groupId);
    //     let unsubscribe: any = null;
    //     if (groupId) {
    //         unsubscribe = firebase
    //             .firestore()
    //             .collection(`groups`)
    //             .doc(groupId)
    //             .collection("groupCategoryMap")
    //             .doc("irrelevant")
    //             .onSnapshot(doc => {
    //                 if (doc.exists) {
    //                     const data = doc.data();
    //                     if (!data) return;
    //                     setIrrelevantGroupCategoryMap(data as IrrelevantGroupCategoryMap);
    //                 }
    //             });
    //     }
    //     return () => {
    //         console.log("unsubscribe");
    //         unsubscribe?.();
    //         setIrrelevantGroupCategoryMap({});
    //     };
    // }, [groupId]);
    return {
        irrelevantGroupCategoryMap,
    };
}
