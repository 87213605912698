import React from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { result } from "./bubbleSample";
import { differenceInDays, format, isSameDay } from "date-fns";
import { Input } from "./StackedBar";

type AuditDateData = {
    dueDate: number; // Unix timestamp in seconds
    completeTime: number; // Unix timestamp in seconds
};

function getScatterdata(auditsRaw: Input[]) {
    // @ts-ignore
    const audits: AuditDateData[] = auditsRaw.map(audit => ({
        dueDate: Number(audit.dueDate) * 1000,
        completeTime: audit.completeTime ? Number(audit.completeTime) * 1000 : null,
    }));

    const auditDatesTodo: AuditDateData[] = audits
        .filter(a => !a.completeTime && a.dueDate >= Date.now())
        .map(audit => ({
            dueDate: audit.dueDate,
            completeTime: audit.completeTime ? audit.completeTime : audit.dueDate,
        }));
    const auditDatesOverdueNotCompleted: AuditDateData[] = audits
        .filter(a => !a.completeTime && a.dueDate < Date.now())
        .map(audit => ({
            dueDate: audit.dueDate,
            completeTime: audit.completeTime ? audit.completeTime : audit.dueDate,
        }));

    // const auditDatesCompleted: AuditDateData[] = audits
    //     .filter(a => a.completeTime)
    //     .map(audit => ({
    //         dueDate: audit.dueDate,
    //         completeTime: audit.completeTime,
    //     }));

    const auditDatesCompletedLate: AuditDateData[] = audits
        .filter(
            a =>
                a.completeTime &&
                !isSameDay(a.completeTime, a.dueDate) &&
                a.completeTime > a.dueDate,
        )
        .map(audit => ({
            dueDate: audit.dueDate,
            completeTime: audit.completeTime,
        }));
    const auditDatesCompletedEarly: AuditDateData[] = audits
        .filter(
            a =>
                a.completeTime &&
                !isSameDay(a.completeTime, a.dueDate) &&
                a.completeTime < a.dueDate,
        )
        .map(audit => ({
            dueDate: audit.dueDate,
            completeTime: audit.completeTime,
        }));

    // Prepare data for Nivo format
    const scatterPlotData = [
        {
            id: "Opgaver overskredet",
            data: auditDatesOverdueNotCompleted.map(audit => ({
                x: new Date(audit.dueDate),
                y: new Date(audit.completeTime),
            })),
        },
        {
            id: "Opgaver afsluttet efter deadline",
            data: auditDatesCompletedLate.map(audit => ({
                x: new Date(audit.dueDate),
                y: new Date(audit.completeTime),
            })),
        },
        {
            id: "Opgaver endnu ikke afsluttet",
            data: auditDatesTodo.map(audit => ({
                x: new Date(audit.dueDate),
                y: new Date(audit.completeTime),
            })),
        },
        // {
        //     id: "Færdige opgaver",
        //     data: auditDatesCompleted.map(audit => ({
        //         x: new Date(audit.dueDate),
        //         y: new Date(audit.completeTime),
        //     })),
        // },

        {
            id: "Opgaver afsluttet før deadline",
            data: auditDatesCompletedEarly.map(audit => ({
                x: new Date(audit.dueDate),
                y: new Date(audit.completeTime),
            })),
        },
    ];
    return scatterPlotData;
}

// console.log({ scatterPlotData });

const ScatterPlotDueVsComplete = ({ audits }: { audits: Input[] }) => {
    const scatterPlotData = getScatterdata(audits);
    return (
        <ResponsiveScatterPlot
            blendMode="multiply"
            // colors={{ scheme: "nivo" }}
            data={scatterPlotData}
            xScale={{
                type: "time",
                format: "%Y-%m-%d",
                precision: "day",
            }}
            yScale={{
                type: "time",
                format: "%Y-%m-%d",
                precision: "day",
            }}
            axisBottom={{
                format: "%b %d",
                legend: "Deadline",
                legendPosition: "middle",
                legendOffset: 46,
            }}
            axisLeft={{
                format: "%b %d",
                legend: "Afslutningsdato",
                legendPosition: "middle",
                legendOffset: -80,
            }}
            nodeSize={10}
            colors={{ scheme: "nivo" }}
            margin={{ top: 50, right: 40, bottom: 70, left: 90 }}
            useMesh={true}
            legends={[
                {
                    anchor: "top",
                    direction: "row",
                    translateY: -30,
                    itemWidth: 180,
                    itemHeight: 10,
                    itemsSpacing: 5,
                    symbolSize: 12,
                },
            ]}
            role="application"
            // ariaLabel="Due Date vs. Completion Date Scatter Plot"
            tooltip={({ node }) => {
                if (!node) return null;
                const isNotCompleted = node.formattedX === node.formattedY;
                const distance = differenceInDays(
                    new Date(node.formattedY),
                    new Date(node.formattedX),
                );
                if (isNotCompleted) {
                    return (
                        <div style={{ backgroundColor: "#fff", padding: 16 }}>
                            <strong>{node.serieId.toString()}</strong>
                            <br />
                            Deadline: {format(new Date(node.formattedX), "dd/MM/yyyy")}
                            <br />
                            Afslutdato:{" "}
                            {isNotCompleted ? "Ikke afsluttet" : node.formattedY}
                        </div>
                    );
                }
                if (distance < 0) {
                    return (
                        <div style={{ backgroundColor: "#fff", padding: 16 }}>
                            <strong>{node.serieId.toString()}</strong>
                            <br />
                            Deadline: {format(new Date(node.formattedX), "dd/MM/yyyy")}
                            <br />
                            Afslutdato: {node.formattedY}
                        </div>
                    );
                }

                return (
                    <div style={{ backgroundColor: "#fff", padding: 16 }}>
                        <strong>{node.serieId.toString()}</strong>
                        <br />
                        Deadline: {format(new Date(node.formattedX), "dd/MM/yyyy")}
                        <br />
                        Afslutdato: {node.formattedY}
                        <br />
                        {distance} dage forsent
                    </div>
                );
            }}
        />
    );
};

export default ScatterPlotDueVsComplete;
