import { ArrowLeftOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Modal, Radio, Row, Space } from "antd";
import { useState } from "react";
import da from "antd/es/date-picker/locale/da_DK";
import { useTranslation } from "react-i18next";
import SearchCategory from "./SearchCategory";
import { StyleGuide } from "styles/StyleGuide";
// import { size } from "../../../functions/src/reports/Heatmap/config";
import OrgsSelect from "./SelectOrgs";
import { getUniqueItemsBySubGroupId } from "./getSubGroups";
import { fakeAPICall } from "general/helpers";
import { useAppSelector } from "hooks";
import { DBGroupShape } from "modules/groups/types";
import { IOrg } from "modules/orgs/interfaces";
import { isDev } from "config";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

// Base64 encoding function
function base64Encode(data: any) {
    return btoa(encodeURIComponent(data));
}

const categoryFilter = isDev
    ? []
    : [
          "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
          "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
      ];

const dateOptions = [
    { label: "Dato", value: "date" },
    { label: "Uge", value: "week" },
    { label: "Måned", value: "month" },
    { label: "Kvartal", value: "quarter" },
    { label: "År", value: "year" },
];

const formatOptionsMap = {
    time: "HH:mm",
    date: "dddd Do MMM YYYY - (Uge ww)",
    week: "Uge ww - YYYY",
    month: "MMM YYYY",
    quarter: "YYYY-[Q]Q",
    year: "YYYY",
};

export default function ReportModal({
    groupIds,
    groupTitle,
    orgs,
    buttonType = "default",
}: // orgIds,
{
    groupIds: string[];
    groupTitle: string;
    orgs: IOrg[];
    buttonType?: "default" | "primary" | "link";
    // orgIds: string[];
}) {
    const [reportType, setReportType] = useState<
        "graphs" | "schema" | "registrations" | null
    >(null);
    const buttonTypeStyle =
        buttonType === "link"
            ? {
                  border: `1px solid ${StyleGuide.palette.infoLight}`,
                  borderRadius: 16,
              }
            : {};
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isOpen, setisOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [useSubGroup, setUseSubGroup] = useState(false);
    const [dateType, setDateType] = useState<
        "time" | "date" | "week" | "month" | "quarter" | "year"
    >("quarter");
    // const groupTitle = useAppSelector(state => state.groups.groupsMap[groupId]?.title);
    // TODO: fix this part with access
    const hasGroupAccess = useAppSelector(state => state.groups.groups?.length > 0);
    const groups = useAppSelector(state => state.groups.groups);

    const handleClose = () => setisOpen(false);
    const selectedOrgIds: string[] = Form.useWatch("selectedIds", {
        form,
        preserve: true,
    });
    const subGroups = getUniqueItemsBySubGroupId(orgs).map(org => ({
        id: org?.subGroup?.id,
        title: org?.subGroup.title,
    }));
    // const groupIds = getUniqueItemsByGroupId(orgs).map(org => org.groupId);

    const useSubGroup = subGroups.length > 0 ? true : false;

    console.log({ subGroups });

    const handleSubmit = async (values: any) => {
        try {
            const categories = getCategories({
                categories: values.categories,
                reportType,
            });

            setIsSubmitting(true);

            const orgIds = getAllOrgIds({
                selectedIds: values.selectedIds,
                groups,
                subGroups,
                orgs,
            });

            console.log({ categories });

            // fake api call
            const encodedValues = base64Encode(
                JSON.stringify({
                    ...values,
                    categories,
                    groupIds,
                    groupTitle,
                    orgIds,
                    dateType,
                }),
            );

            console.log(encodedValues);

            await fakeAPICall();
            if (reportType === "graphs") {
                window.open(`/reports/data/audits?q=${encodedValues}`, "_blank");
            }
            if (reportType === "schema") {
                window.open(`/reports/audits?q=${encodedValues}`, "_blank");
            }
            if (reportType === "registrations") {
                window.open(`/reports/registrations/audits?q=${encodedValues}`, "_blank");
            }
            setisOpen(false);
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    function renderRapportType() {
        if (reportType) return null;

        return (
            <Row gutter={24} style={{ width: "100%", marginBottom: 16 }}>
                <Col md={8}>
                    <Card
                        hoverable
                        style={{ padding: 8, minHeight: 270, cursor: "pointer" }}
                        cover={<img alt="example" src={require("./graphs.jpg")} />}
                        onClick={() => setReportType("graphs")}>
                        <Card.Meta title="Grafer/data overblik" />
                    </Card>
                </Col>
                <Col md={8}>
                    <Card
                        hoverable
                        style={{ padding: 8, minHeight: 270, cursor: "pointer" }}
                        cover={<img alt="example" src={require("./schema.png")} />}
                        onClick={() => setReportType("schema")}>
                        <Card.Meta title="Opgave skema overblik" />
                    </Card>
                </Col>
                <Col md={8}>
                    <Card
                        hoverable
                        style={{ padding: 8, minHeight: 270, cursor: "pointer" }}
                        cover={<img alt="example" src={require("./registrations.png")} />}
                        onClick={() => setReportType("registrations")}>
                        <Card.Meta title="Registreringer" />
                    </Card>
                </Col>
            </Row>
        );
    }

    function renderRapportSettings() {
        if (!reportType) return null;
        return (
            <Form
                form={form}
                layout="inline"
                initialValues={{
                    incompleteTasks: true,
                    completedTasks: true,
                    dateInterval: undefined,
                }}
                onFinish={handleSubmit}>
                <Row gutter={24} style={{ width: "100%", marginBottom: 16 }}>
                    <Col md={24}>
                        <Row style={{ width: "100%", marginBottom: 16 }}>
                            <Col md={24}>
                                <FormItem
                                    name="categories"
                                    style={{ width: "100%" }}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please select a template!",
                                        },
                                    ]}>
                                    {reportType === "registrations" ? (
                                        <SearchCategory
                                            categoriesFilter={categoryFilter}
                                            groupIds={groupIds}
                                        />
                                    ) : (
                                        <SearchCategory groupIds={groupIds} />
                                    )}
                                </FormItem>
                                <div
                                    style={{
                                        textAlign: "right",
                                        color: StyleGuide.palette.grey600,
                                    }}>
                                    * efterlad feltet tomt for at vælge alle
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%", marginBottom: 16 }}>
                            {/* {subGroups?.length > 0 && (
                                            <Col md={24} style={{ textAlign: "right" }}>
                                                <Radio.Group
                                                    options={[
                                                        {
                                                            label: "Brug specfik afdeling",
                                                            value: false,
                                                        },
                                                        {
                                                            label: "Brug generel afdeling",
                                                            value: true,
                                                        },
                                                    ]}
                                                    onChange={e =>
                                                        setUseSubGroup(e.target.value)
                                                    }
                                                    value={useSubGroup}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Col>
                                        )} */}

                            <Col md={24} style={{ marginTop: 8 }}>
                                <FormItem
                                    name="selectedIds"
                                    style={{ width: "100%" }}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please select a template!",
                                        },
                                    ]}>
                                    {/* @ts-ignore */}
                                    <OrgsSelect
                                        size="large"
                                        groupIds={groupIds}
                                        hasGroupAccess={hasGroupAccess}
                                        orgs={useSubGroup ? subGroups : orgs}
                                        orgIds={selectedOrgIds}
                                        mode="multiple"
                                        selectedIds={(newValue: string[] | string) => {
                                            form.setFieldValue(
                                                "selectedIds",
                                                newValue as string[],
                                            );
                                        }}
                                    />
                                </FormItem>
                                <div
                                    style={{
                                        textAlign: "right",
                                        color: StyleGuide.palette.grey600,
                                    }}>
                                    * efterlad feltet tomt for at vælge alle
                                </div>
                            </Col>
                        </Row>

                        <Row style={{ width: "100%", marginBottom: 16 }}>
                            <Col md={8}>{t("audit.schedule.datelabel")}</Col>
                            <Col md={16} style={{ textAlign: "right" }}>
                                <Radio.Group
                                    //@ts-ignore
                                    options={dateOptions}
                                    onChange={e => setDateType(e.target.value)}
                                    value={dateType}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Col>
                            <Col md={24} style={{ marginTop: 8 }}>
                                <FormItem
                                    name="dateInterval"
                                    style={{ width: "100%" }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a date!",
                                        },
                                    ]}>
                                    <RangePicker
                                        // placeholder="Vælg dato"
                                        format={formatOptionsMap[dateType]} //"dddd Do MMM YYYY - (Uge ww)"}
                                        style={{ width: "100%" }}
                                        size="large"
                                        picker={dateType}
                                        locale={da}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col
                                    md={8}
                                    style={{
                                        borderLeft: "1px solid",
                                        borderColor: StyleGuide.palette.grey300,
                                    }}>
                                    <FormItem
                                        name="completedTasks"
                                        valuePropName="checked">
                                        <Checkbox
                                            checked={completedTasks}
                                            onChange={e => {
                                                form.setFieldValue(
                                                    "completedTasks",
                                                    e.target.checked,
                                                );
                                            }}>
                                            Inkludér afsluttede opgaver
                                        </Checkbox>
                                        <InfoCircleOutlined />
                                    </FormItem>
                                    <FormItem
                                        name="incompleteTasks"
                                        valuePropName="checked">
                                        <Checkbox
                                            checked={incompleteTasks}
                                            onChange={e => {
                                                form.setFieldValue(
                                                    "incompleteTasks",
                                                    e.target.checked,
                                                );
                                            }}>
                                            Inkludér uafsluttede opgaver
                                        </Checkbox>
                                        <InfoCircleOutlined />
                                    </FormItem>
                                </Col> */}
                </Row>

                <FormItem style={{ width: "100%" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                        block
                        style={{ width: "100%" }}>
                        {t("audit.schedule.submit")}
                    </Button>
                </FormItem>
            </Form>
        );
    }
    return (
        <>
            <Button
                style={buttonTypeStyle}
                type={buttonType}
                onClick={() => setisOpen(true)}
                icon={<FilePdfOutlined />}>
                Rapporter
            </Button>

            <Modal
                className="schedule-modal"
                width={"80%"}
                style={{
                    position: "relative",
                }}
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
                open={isOpen}
                onCancel={handleClose}
                footer={[]}>
                <Row>
                    <Col span={24} style={{ padding: 24 }}>
                        <Space>
                            {reportType && (
                                <Button
                                    onClick={() => setReportType(null)}
                                    type="text"
                                    icon={<ArrowLeftOutlined />}
                                />
                            )}
                            <h1>
                                {t("dashboard.reportModal.modalTitle", {
                                    entityName: groupTitle,
                                })}
                            </h1>
                        </Space>
                        {renderRapportType()}
                        {renderRapportSettings()}
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

function getCategories({
    categories,
    reportType,
}: {
    categories?: string[];
    reportType: "graphs" | "schema" | "registrations" | null;
}) {
    if ((!categories || categories?.length < 1) && reportType === "registrations") {
        return categoryFilter;
    }
    return categories;
}

function getAllOrgIds({
    selectedIds,
    groups = [],
    subGroups = [],
    orgs = [],
}: {
    selectedIds: string[];
    groups: DBGroupShape[];
    subGroups: { id: string; title: string }[];
    orgs: IOrg[];
}): string[] {
    // if no selected ids, return all org ids
    if (!selectedIds || selectedIds?.length < 1) return orgs.map(org => org.id);
    const orgIds: string[] = [];
    selectedIds.forEach((selectedId: string) => {
        const isGroup = groups.find(group => group.id === selectedId);
        const isSubGroup = subGroups.find(subGroup => subGroup.id === selectedId);
        const isOrg = orgs.find(org => org.id === selectedId);

        if (isGroup) {
            const orgIdsInGroup = orgs
                .filter(org => org.groupId === selectedId)
                .map(org => org.id);
            orgIds.push(...orgIdsInGroup);
        }

        if (isSubGroup) {
            const orgIdsInSubGroup = orgs
                .filter(org => org.subGroup?.id === selectedId)
                .map(org => org.id);
            orgIds.push(...orgIdsInSubGroup);
        }
        if (isOrg) {
            orgIds.push(selectedId);
        }
    });

    // only unique ids
    return Array.from(new Set(orgIds));
}
