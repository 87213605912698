import { Button, Col, List, Popover, Row, Tooltip } from "antd";
import useAuditSchedulesPreview from "hooks/useAuditSchedulesPreview";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { getScheduleByEnum } from "./scheduleHelpers";
import { useAppSelector } from "hooks";
import { StyleGuide } from "styles/StyleGuide";
import { HistoryOutlined } from "@ant-design/icons";
import { QUICK_TASK, RecurringOptions } from "general/enums";
import { startOfDay } from "date-fns";
import { displayRepeatRule } from "./EditScheduleModal";
import { renderCustomPreview, renderPreview } from "./ScheduleModal";

export default function AlreadyScheduled({
    templateId,
    date = startOfDay(new Date()),
}: {
    date?: Date;
    templateId: string;
}) {
    const intl = useAppSelector(state => state.user.userDoc.intl);
    const { orgId } = useParams<{ orgId: string }>();
    const { t } = useTranslation();
    const { audits, loading } = useAuditSchedulesPreview({
        orgId,
        auditTemplateId: templateId,
        date,
    });
    // console.log({ date, orgId, templateId });
    // console.log({ audits, loading });
    if (templateId && QUICK_TASK.OPTION !== templateId) {
        return (
            <Col
                md={6}
                style={{
                    backgroundColor: StyleGuide.palette.grey100,
                    borderRadius: 8,
                    padding: 24,
                }}>
                <h4 style={{ whiteSpace: "pre-line" }}>
                    {t("audit.schedule.sametemplate.title")}
                </h4>
                <Row>
                    <Col span={24}>
                        <List
                            // size="large"
                            // bordered
                            loading={loading}
                            dataSource={audits}
                            locale={{ emptyText: t("audit.schedule.noScheduled") }}
                            renderItem={item => (
                                <List.Item style={{ padding: "8px 0px" }}>
                                    <CustomPreview audit={item} />
                                    <SchedulePreview audit={item} />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }
    return null;
}

function SchedulePreview({ audit }: { audit: any }) {
    const { t } = useTranslation();
    const intl = useAppSelector(state => state.user.userDoc.intl);
    const item = audit;
    console.log({ item });

    if (item?.repeatRule) {
        return null;
    }

    return (
        <Popover
            content={renderPreview(item.recurringOption, item.dueDate.toDate(), intl, t)}
            title="Forhåndsvisning">
            <List.Item.Meta
                title={item.templateTitle}
                description={
                    <div style={{ fontSize: 12 }}>
                        {item.recurringOption === RecurringOptions.NEVER && (
                            <div>
                                {item?.startDate?.toDate() &&
                                    `${t("general.dateTime", {
                                        date: item.startDate.toDate(),
                                    })}`}
                                {item?.startDate?.toDate()?.getTime() !==
                                    item?.dueDate?.toDate()?.getTime() &&
                                    ` - ${t("general.dateTime", {
                                        date: item.dueDate?.toDate(),
                                    })}`}
                            </div>
                        )}
                        {item.recurringId && (
                            <div>
                                <HistoryOutlined /> {t("audit.schedule.repeatlabel")}
                                {": "}
                                {getScheduleByEnum(
                                    item.recurringOption,
                                    item.dueDate.toDate(),
                                    intl,
                                )}
                            </div>
                        )}
                    </div>
                }
            />
        </Popover>
    );
}

function CustomPreview({ audit }: { audit: any }) {
    const { t } = useTranslation();
    const intl = useAppSelector(state => state.user.userDoc.intl);
    const item = audit;

    if (!item.repeatRule) {
        return null;
    }
    return (
        <Popover
            content={renderCustomPreview(item.repeatRule, intl, t)}
            title="Forhåndsvisning">
            <List.Item.Meta
                title={item.templateTitle}
                description={
                    <div style={{ fontSize: 12 }}>
                        <div>
                            <HistoryOutlined /> {t("audit.schedule.repeatlabel")}
                            {": "}
                            {displayRepeatRule(item.repeatRule)}
                        </div>
                    </div>
                }
            />
        </Popover>
    );
}
