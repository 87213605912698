import { useEffect, useState } from "react";
import useQueryParams from "hooks/useQueryParams";
import { CaretRightFilled, FilePdfOutlined, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./page.css";
import { Button } from "antd";
import { getDateInterval } from "./helpers";
import AdminOnly from "components/AdminOnly";
import { useAppSelector } from "hooks";
import Message, { Type } from "components/Notification/Message";
import groupBy from "lodash.groupby";
import CategoryBlock from "./CategoryBlock";
import OrgBlock from "./OrgBlock";
import TaskBlock from "./TaskBlock";
import { functions } from "../../firebase";
// Base64 decoding function
function base64Decode(data: any) {
    return decodeURIComponent(atob(data));
}

const displayRGSLogo = (groupIds: string[]) =>
    [
        "RuXfdQ77eGHqAsQSHK5m",
        "Gua3ROcmn9CBLIGSpOVn",
        "szGrXKI9MAya8vPFG3wT",
        "46zYSYgrJQ68tjYqMhSe",
    ].some(id => groupIds.includes(id));

export default function AuditReportsRegistrations() {
    const { t } = useTranslation();
    const query = useQueryParams();
    const [loading, setLoading] = useState(false);
    const [audits, setAudits] = useState<any[]>([]);
    const q = query.get("q") as string;
    const decodedData = base64Decode(q);
    const [error, setError] = useState<number | null>(null);

    const parsedData = JSON.parse(decodedData) as {
        groupIds: string[];
        groupTitle: string;
        categories: string[];
        orgIds: string[];
        dateInterval: [string, string];
        completedTasks: boolean;
        incompleteTasks: boolean;
        dateType: "time" | "date" | "week" | "month" | "quarter" | "year";
    };

    /*        {
        groupIds: parsedData.groupId,
    }*/

    const [startDate, endDate] = getDateInterval(
        parsedData.dateInterval,
        parsedData.dateType,
    );

    const displayRGS = displayRGSLogo(parsedData.groupIds);

    const orgs = useAppSelector(state => state.orgs.orgMap);

    const subGroups = parsedData.orgIds
        .map(orgId => orgs[orgId]?.subGroup)
        .filter(Boolean);

    const filtered = subGroups
        ?.map(subGroup => subGroup?.title)
        .filter(s => s !== undefined);

    const title = filtered?.join(", ");

    // const subGroups = parsedData.useSubGroup
    //     ? (subGroups.filter(Boolean) as string[])
    //     : [];

    useEffect(() => {
        if (error === 403) {
            Message({
                key: "error",
                type: Type.ERROR,
                message: "Du har ikke adgang til denne rapport",
                description:
                    "Du har mangler adgang til en eller flere af de valgte afdelinger for at kunne trække denne rapport",
            });
        }
        if (error === 404) {
            Message({
                key: "error",
                type: Type.ERROR,
                message: "Rapporten kunne ikke findes",
                description:
                    "Rapporten kunne ikke findes. Den er muligvis blevet slettet eller har udløbet",
            });
        }
        if (error === 500) {
            Message({
                key: "error",
                type: Type.ERROR,
                message: "Der opstod en fejl",
                description:
                    "Der opstod en fejl under generering af rapporten. Prøv igen senere",
            });
        }
    }, [error]);

    // useEffect(() => {
    //     const getDeals = async () => {
    //         try {
    //             setLoading(true);
    //             // const fakeAPICall = (): Promise<void> => {
    //             //     return new Promise(resolve => {
    //             //         setTimeout(() => {
    //             //             resolve();
    //             //         }, 2000);
    //             //     });
    //             // };

    //             const functionRef = functions.httpsCallable("generateAuditReport");
    //             const response: any = await functionRef({
    //                 ...parsedData,
    //             });
    //             const errorCode = response?.data?.httpErrorCode?.status;
    //             if (errorCode) {
    //                 console.log({ errorCode });
    //             }
    //             setData(groupByMonth(response.data));
    //             setSums(sumCustomFields(response.data));
    //         } catch (error) {
    //             console.error(error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     getDeals();

    //     return () => {};
    // }, []);
    useEffect(() => {
        // setAudits(fakeData);
        // setLoading(false);

        const getAudits = async () => {
            try {
                setLoading(true);
                setError(null);

                const functionRef = functions.httpsCallable("generateAuditSchema");
                const response: any = await functionRef({
                    ...parsedData,
                });

                const errorCode = response?.data?.httpErrorCode?.status;
                if (errorCode) {
                    setError(errorCode as number);
                    throw new Error(errorCode);
                }
                setAudits(
                    response.data
                        // .filter((e: any) => !e.auditTemplateId)
                        .map((entry: any) => ({
                            ...entry,
                            dueDate: Number(entry.dueDate) * 1000,
                            completeTime: entry.completeTime
                                ? Number(entry.completeTime) * 1000
                                : null,
                            isCompleted: entry.isCompleted === "true",
                            hasOnHoldTasks: entry.hasOnHoldTasks === "true",
                            needsResolution: entry.needsResolution === "true",
                            tasks: JSON.parse(entry.tasks),
                        })),
                );
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getAudits();

        return () => {};
    }, []);
    console.log({ audits });

    const categories = getAllUniqueCategories(audits);

    const auditsByOrg = groupBy(audits, "orgTitle");

    return (
        <div className="report-page">
            <AdminOnly>
                <div
                    className="print-hide"
                    style={{ position: "absolute", left: 24, top: 24 }}>
                    <Button icon={<FilePdfOutlined />} onClick={() => window.print()}>
                        Gem som PDF
                    </Button>
                </div>
            </AdminOnly>

            {displayRGS && (
                <div style={{ textAlign: "right" }}>
                    <img
                        width="300"
                        src="https://rgsnordic.com/wp-content/uploads/Logo-Wide.png"
                    />
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <div>
                    <h1>Rapport {parsedData.groupTitle || title}</h1>
                    <div style={{ paddingBottom: 24 }}>
                        {t("date.exactFormatDateYear", {
                            dayOfWeek: new Date(startDate),
                            dayOfMonth: new Date(startDate),
                            month: new Date(startDate),
                            year: new Date(startDate),
                        })}{" "}
                        -{" "}
                        {t("date.exactFormatDateYear", {
                            dayOfWeek: new Date(endDate),
                            dayOfMonth: new Date(endDate),
                            month: new Date(endDate),
                            year: new Date(endDate),
                        })}
                    </div>
                    {/* <div>{parsedData.categories.map(c => categoryMap[c]).join(", ")}</div> */}
                </div>
            </div>
            {loading ? (
                <LoadingOutlined />
            ) : (
                <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                    {categories.length === 0 && (
                        <div style={{ textAlign: "center", color: "gray" }}>
                            <h2>Ingen opgaver for perioden</h2>
                        </div>
                    )}
                    {categories.map(id => {
                        const auditsByCategory = audits.filter(
                            a => a.auditTemplateId === id,
                        );
                        const allCompleted = auditsByCategory.every(a => a.isCompleted);
                        const noneCompleted = auditsByCategory.every(a => !a.isCompleted);
                        const summedForCategory = auditsByCategory.map(a =>
                            getSummedValueFromAudit(a),
                        );

                        const isPartialCompleted = !allCompleted && !noneCompleted;

                        return (
                            <CategoryBlock
                                isPartialCompleted={isPartialCompleted}
                                allCompleted={allCompleted}
                                // @ts-ignore
                                id={categoryMap[id] as string}
                                summedForCategory={summedForCategory}>
                                {Object.entries(auditsByOrg)
                                    .sort()
                                    .map(([title, audits]: any) => {
                                        const filterOrgs = auditsByCategory.filter(a =>
                                            a.orgTitle.includes(title),
                                        );
                                        const allCompleted = filterOrgs.every(
                                            a => a.isCompleted,
                                        );
                                        const noneCompleted = filterOrgs.every(
                                            a => a.isCompleted,
                                        );

                                        const isPartialCompleted =
                                            !allCompleted && !noneCompleted;

                                        const moreSummed = filterOrgs.map(a =>
                                            getSummedValueFromAudit(a),
                                        );

                                        return (
                                            <OrgBlock
                                                allCompleted={allCompleted}
                                                isPartialCompleted={isPartialCompleted}
                                                title={title}
                                                moreSummed={moreSummed}>
                                                {audits
                                                    .filter(
                                                        (a: any) =>
                                                            a.auditTemplateId === id,
                                                    )
                                                    .map((audit: any) => {
                                                        return (
                                                            <TaskBlock
                                                                tasks={audit.tasks}
                                                            />
                                                        );
                                                    })}
                                            </OrgBlock>
                                        );
                                    })}
                            </CategoryBlock>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

const categoryMap = {
    "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a": "Årligt dieselforbrug",
    "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a": "Årligt elforbrug",
};

const fakeData = [
    {
        auditId: "fVK3W9B4dKogXVv9RUfD",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "true",
        completeTime: "1737035969",
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Aalborg Savannevej - Miljømål",
        orgId: "EmPyM8IbPOMIVGixxABZ",
        subGroup: "RGS Nordic A/S - Vest - Aalborg Savannevej",
        tasks: {
            vhFj3k4tTYSKrk1uSeqU: {
                completeTime: { _seconds: 1737035957, _nanoseconds: 635000000 },
                createTime: "2025-01-16T13:12:59.164Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                        value: 47705,
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "vhFj3k4tTYSKrk1uSeqU",
                isCompleted: true,
                order: 0,
            },
        },
    },
    {
        auditId: "5I5NtekLJ8U7UC0PBpvA",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "szGrXKI9MAya8vPFG3wT",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Genbrug - Selinevej port 3 - Miljømål",
        orgId: "1n7UGbvhzUFRVlAd0X2d",
        subGroup: "RGS - CPH Genbrug - Selinevej port 3",
        tasks: {
            "8TzmJ1oGTmZ8f5vutJJz": {
                createTime: "2025-01-16T12:21:54.688Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "8TzmJ1oGTmZ8f5vutJJz",
                order: 0,
            },
        },
    },
    {
        auditId: "jRCTUnu2N58iQFBabqns",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "46zYSYgrJQ68tjYqMhSe",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Jord - Nordsøvej - Miljømål",
        orgId: "45iGajxZXyYuuMbcI9rc",
        subGroup: "RGS - CPH Jord - Nordsøvej",
        tasks: {
            Kt4M1tYUttzYZ9L3UZ6c: {
                createTime: "2025-01-16T12:31:23.310Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "Kt4M1tYUttzYZ9L3UZ6c",
                order: 0,
            },
        },
    },
    {
        auditId: "IOt0X45DFyATLvUaOUyG",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Glatved/Balle - Miljømål",
        orgId: "BNyOc95aqFkA9Wlyq9Mz",
        subGroup: "RGS Nordic A/S - Vest - Glatved/Balle",
        tasks: {
            FrKWva9xsOlu2ZgzlNSs: {
                createTime: "2025-01-16T13:02:50.228Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "FrKWva9xsOlu2ZgzlNSs",
                order: 0,
            },
        },
    },
    {
        auditId: "N5JJDzZPsGUVRvO2k7Kz",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Horsens - Miljømål",
        orgId: "F2O5bfX3EVTI1jXkhKRY",
        subGroup: "RGS Nordic A/S - Vest - Horsens",
        tasks: {
            uvP3A6AWf0CS001F9xV7: {
                createTime: "2025-01-16T13:05:36.168Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "uvP3A6AWf0CS001F9xV7",
                order: 0,
            },
        },
    },
    {
        auditId: "Hb5BcmpX8IoBxh2iwDWo",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Horsens - Miljømål",
        orgId: "F2O5bfX3EVTI1jXkhKRY",
        subGroup: "RGS Nordic A/S - Vest - Horsens",
        tasks: {
            PzibaG5FwhmsrafPHfjU: {
                createTime: "2025-01-16T13:05:13.607Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "PzibaG5FwhmsrafPHfjU",
                order: 0,
            },
        },
    },
    {
        auditId: "3lNruKSY7Wby7XBz21cd",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Esbjerg - Miljømål",
        orgId: "FFRl86Jeny7ufDUxiBHd",
        subGroup: "RGS Nordic A/S - Vest - Esbjerg",
        tasks: {
            tdSBYAuWBGEXpjLYoSOR: {
                createTime: "2025-01-16T12:59:34.841Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "tdSBYAuWBGEXpjLYoSOR",
                order: 0,
            },
        },
    },
    {
        auditId: "J8EGVRKoW6PEcwVNFLmX",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Odense - Miljømål",
        orgId: "H1Aqkh1PvdXBv9aRY8ad",
        subGroup: "RGS Nordic A/S - Vest - Odense",
        tasks: {
            "9GcV7RDt3lxvAclMzxU6": {
                createTime: "2025-01-16T13:08:04.533Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "9GcV7RDt3lxvAclMzxU6",
                order: 0,
            },
        },
    },
    {
        auditId: "C5fgt6EfiS5VSb9TCe4i",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Odense - Miljømål",
        orgId: "H1Aqkh1PvdXBv9aRY8ad",
        subGroup: "RGS Nordic A/S - Vest - Odense",
        tasks: {
            klpNBk2mnJH4FAk456Dd: {
                createTime: "2025-01-16T13:07:43.253Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "klpNBk2mnJH4FAk456Dd",
                order: 0,
            },
        },
    },
    {
        auditId: "sgEmD2BA3yjGPe9V7EQH",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Kolding - Miljømål",
        orgId: "HGQYXEY6N8LLZinq1OUc",
        subGroup: "RGS Nordic A/S - Vest - Kolding",
        tasks: {
            oBiWpe1Brwrx9WB8Bv2W: {
                createTime: "2025-01-16T13:06:42.320Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "oBiWpe1Brwrx9WB8Bv2W",
                order: 0,
            },
        },
    },
    {
        auditId: "PJ9CFUuRVPBhoCEIJMsw",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Kolding - Miljømål",
        orgId: "HGQYXEY6N8LLZinq1OUc",
        subGroup: "RGS Nordic A/S - Vest - Kolding",
        tasks: {
            x07KvUDZG838NUYOt81e: {
                createTime: "2025-01-16T13:06:19.366Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "x07KvUDZG838NUYOt81e",
                order: 0,
            },
        },
    },
    {
        auditId: "H5U98RhJ2oSuU9pGAst9",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Fasterholt - Miljømål",
        orgId: "I6fWCNBBh7prGo3ewrOv",
        subGroup: "RGS Nordic A/S - Vest - Fasterholt",
        tasks: {
            gIIiT6FANqEhhxpmV1aN: {
                createTime: "2025-01-16T13:01:43.465Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "gIIiT6FANqEhhxpmV1aN",
                order: 0,
            },
        },
    },
    {
        auditId: "EQNmLz8MZrA2xJP4SYJv",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "szGrXKI9MAya8vPFG3wT",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Genbrug - Selinevej port 5 - Miljømål",
        orgId: "IOeedHDwQTpxahtnvRbl",
        subGroup: "RGS - CPH Genbrug - Selinevej port 5",
        tasks: {
            thDcZEm6BE1moXVfvScJ: {
                createTime: "2025-01-16T12:28:03.245Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "thDcZEm6BE1moXVfvScJ",
                order: 0,
            },
        },
    },
    {
        auditId: "2x9G0nF27OFj6luqckYh",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Vemmelev - Miljømål",
        orgId: "QZDH2TKXFkJBNHsUMWLX",
        subGroup: "RGS - Produktion Sjælland - Vemmelev",
        tasks: {
            bfvkXiIHB5Ec1Hj1jTHP: {
                createTime: "2025-01-16T12:54:18.493Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "bfvkXiIHB5Ec1Hj1jTHP",
                order: 0,
            },
        },
    },
    {
        auditId: "6SoIwM71bXLKRk6clGHP",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Vemmelev - Miljømål",
        orgId: "QZDH2TKXFkJBNHsUMWLX",
        subGroup: "RGS - Produktion Sjælland - Vemmelev",
        tasks: {
            wfV48E44PHDi2gLqjCWw: {
                createTime: "2025-01-16T12:54:42.227Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "wfV48E44PHDi2gLqjCWw",
                order: 0,
            },
        },
    },
    {
        auditId: "H9FtGNjL5wb2jWHthXRf",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Sanderødgård - Miljømål",
        orgId: "RNDTM0RYhw8YLgHxP0Sf",
        subGroup: "RGS - Produktion Sjælland - Sanderødgård",
        tasks: {
            mNNui5yeQ0jmjcxTG5ZB: {
                createTime: "2025-01-16T12:50:33.775Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "mNNui5yeQ0jmjcxTG5ZB",
                order: 0,
            },
        },
    },
    {
        auditId: "APmRWVeTLhrahBZ2X3Eh",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Sanderødgård - Miljømål",
        orgId: "RNDTM0RYhw8YLgHxP0Sf",
        subGroup: "RGS - Produktion Sjælland - Sanderødgård",
        tasks: {
            R8ZsGec4Ue42Xx3WMPiB: {
                createTime: "2025-01-16T12:51:17.237Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "R8ZsGec4Ue42Xx3WMPiB",
                order: 0,
            },
        },
    },
    {
        auditId: "fVOsv8fpDmrAR54VCJWP",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Rødby - Miljømål",
        orgId: "YXdtSTiaTIPfEy5Mt8do",
        subGroup: "RGS - Produktion Sjælland - Rødby",
        tasks: {
            IxS140y0xnaVbOC944Y8: {
                createTime: "2025-01-16T12:49:07.817Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "IxS140y0xnaVbOC944Y8",
                order: 0,
            },
        },
    },
    {
        auditId: "utp9lEdC9UNEcRDbUcSD",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Rødby - Miljømål",
        orgId: "YXdtSTiaTIPfEy5Mt8do",
        subGroup: "RGS - Produktion Sjælland - Rødby",
        tasks: {
            M8NkRFEWnuUB1mBSX3yl: {
                createTime: "2025-01-16T12:49:31.373Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "M8NkRFEWnuUB1mBSX3yl",
                order: 0,
            },
        },
    },
    {
        auditId: "w8RTqPkaB8uhiBRqAiFU",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "szGrXKI9MAya8vPFG3wT",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Genbrug - Vindmøllevej - Miljømål",
        orgId: "aGWAnt7KjdVa8dr5dgR9",
        subGroup: "RGS - CPH Genbrug - Vindmøllevej",
        tasks: {
            NnS4LYNwpccNiq376hwr: {
                createTime: "2025-01-16T12:30:23.121Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "NnS4LYNwpccNiq376hwr",
                order: 0,
            },
        },
    },
    {
        auditId: "8O2DV4C4OuOUlHOySVLT",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Gadstrup - Miljømål",
        orgId: "b8lQZcH3bkHeNddxHZVd",
        subGroup: "RGS - Produktion Sjælland - Gadstrup",
        tasks: {
            u27j8MwlAs8uQkzmiltn: {
                createTime: "2025-01-16T12:44:38.349Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "u27j8MwlAs8uQkzmiltn",
                order: 0,
            },
        },
    },
    {
        auditId: "gIo2gip5bg2T6Rf3bT1t",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Stigsnæs - Miljømål",
        orgId: "bOvU0YeYhZbZsPNkistW",
        subGroup: "RGS - Produktion Sjælland - Stigsnæs",
        tasks: {
            d0b5beggQzxPW0xjNb8l: {
                createTime: "2025-01-16T12:52:58.186Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "d0b5beggQzxPW0xjNb8l",
                order: 0,
            },
        },
    },
    {
        auditId: "4wKCzF8kCXMZ5HpRQrsJ",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Stigsnæs - Miljømål",
        orgId: "bOvU0YeYhZbZsPNkistW",
        subGroup: "RGS - Produktion Sjælland - Stigsnæs",
        tasks: {
            wQFthxLSyoDV7VsOS1hC: {
                createTime: "2025-01-16T12:53:22.732Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "wQFthxLSyoDV7VsOS1hC",
                order: 0,
            },
        },
    },
    {
        auditId: "sab3wb5X4BHNW7tCaMVs",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Herlufmagle - Miljømål",
        orgId: "md32SWSvJjcO4sq7oQWr",
        subGroup: "RGS - Produktion Sjælland - Herlufmagle",
        tasks: {
            kxYFRUMe7eBx58Wukz7q: {
                createTime: "2025-01-16T12:46:46.343Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "kxYFRUMe7eBx58Wukz7q",
                order: 0,
            },
        },
    },
    {
        auditId: "bE3DdxVAsyEnEmBromNM",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Herlufmagle - Miljømål",
        orgId: "md32SWSvJjcO4sq7oQWr",
        subGroup: "RGS - Produktion Sjælland - Herlufmagle",
        tasks: {
            "4b305jUy3qdpsiGDXBYt": {
                createTime: "2025-01-16T12:46:26.664Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "4b305jUy3qdpsiGDXBYt",
                order: 0,
            },
        },
    },
    {
        auditId: "z97Z59ARaJGMa9hMoW5d",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Hjøllund - Miljømål",
        orgId: "sUcZaGNTDqAp3uesMw2h",
        subGroup: "RGS Nordic A/S - Vest - Hjøllund",
        tasks: {
            JMxXc3GY22kAf6wWwy6Y: {
                createTime: "2025-01-16T13:03:57.006Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "JMxXc3GY22kAf6wWwy6Y",
                order: 0,
            },
        },
    },
    {
        auditId: "05vrQDcUD5uNuhKUyZ2o",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "46zYSYgrJQ68tjYqMhSe",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Jord - Selinevej port 4,6,7 - Miljømål",
        orgId: "zK8FBFB8aGxIqXf40kYD",
        subGroup: "RGS - CPH Jord - Selinevej port 4,6,7",
        tasks: {
            yG3AC16EAkI3kfI4FJcA: {
                createTime: "2025-01-16T12:32:38.353Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "yG3AC16EAkI3kfI4FJcA",
                order: 0,
            },
        },
    },
    {
        auditId: "1qyb8NGB7IyUPSbKbMC9",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "46zYSYgrJQ68tjYqMhSe",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Jord - Selinevej port 4,6,7 - Miljømål",
        orgId: "zK8FBFB8aGxIqXf40kYD",
        subGroup: "RGS - CPH Jord - Selinevej port 4,6,7",
        tasks: {
            yh1NIIHvxrZftiLcMhcD: {
                createTime: "2025-01-16T12:32:57.031Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "yh1NIIHvxrZftiLcMhcD",
                order: 0,
            },
        },
    },
    {
        auditId: "jLlY4ThxyzpSkIEUSaYM",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "szGrXKI9MAya8vPFG3wT",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Genbrug - Selinevej port 3 - Miljømål",
        orgId: "1n7UGbvhzUFRVlAd0X2d",
        subGroup: "RGS - CPH Genbrug - Selinevej port 3",
        tasks: {
            P1ahBf0SLvPxDCEzskFi: {
                createTime: "2025-01-16T12:24:37.050Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "P1ahBf0SLvPxDCEzskFi",
                order: 0,
            },
        },
    },
    {
        auditId: "kjLnXwlOPSAFWiUi2XyF",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "46zYSYgrJQ68tjYqMhSe",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Jord - Nordsøvej - Miljømål",
        orgId: "45iGajxZXyYuuMbcI9rc",
        subGroup: "RGS - CPH Jord - Nordsøvej",
        tasks: {
            Cx20APGe2tj21BJCz43B: {
                createTime: "2025-01-16T12:31:46.455Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "Cx20APGe2tj21BJCz43B",
                order: 0,
            },
        },
    },
    {
        auditId: "pMHRDPSuETOls3nDLZx7",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "szGrXKI9MAya8vPFG3wT",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Genbrug - Selinevej port 5 - Miljømål",
        orgId: "IOeedHDwQTpxahtnvRbl",
        subGroup: "RGS - CPH Genbrug - Selinevej port 5",
        tasks: {
            a328OS7ALjkofGyJoTHs: {
                createTime: "2025-01-16T12:28:34.346Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "a328OS7ALjkofGyJoTHs",
                order: 0,
            },
        },
    },
    {
        auditId: "3v0Xrrk0ZwjT1bdiXBUt",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "szGrXKI9MAya8vPFG3wT",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - CPH Genbrug - Vindmøllevej - Miljømål",
        orgId: "aGWAnt7KjdVa8dr5dgR9",
        subGroup: "RGS - CPH Genbrug - Vindmøllevej",
        tasks: {
            aOxBqAqBAC7gc7Xvl5EO: {
                createTime: "2025-01-16T12:30:00.935Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "aOxBqAqBAC7gc7Xvl5EO",
                order: 0,
            },
        },
    },
    {
        auditId: "lOc1tnF7VkrlBiDljAlg",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Gadstrup - Miljømål",
        orgId: "b8lQZcH3bkHeNddxHZVd",
        subGroup: "RGS - Produktion Sjælland - Gadstrup",
        tasks: {
            SJiHbHfPgdBPfcaFEXlz: {
                createTime: "2025-01-16T12:44:55.154Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "SJiHbHfPgdBPfcaFEXlz",
                order: 0,
            },
        },
    },
    {
        auditId: "y0CMboHQrs1xnz4lqkC6",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Esbjerg - Miljømål",
        orgId: "FFRl86Jeny7ufDUxiBHd",
        subGroup: "RGS Nordic A/S - Vest - Esbjerg",
        tasks: {
            BYwE8r3MGNdmn0FlTBFA: {
                createTime: "2025-01-16T13:00:15.713Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "BYwE8r3MGNdmn0FlTBFA",
                order: 0,
            },
        },
    },
    {
        auditId: "WfDsMQYsKtmSa8RKZpmo",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "true",
        completeTime: "1737036534",
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Kalundborg - Miljømål",
        orgId: "NRiWvZz6lBLjvvPpR5vY",
        subGroup: "RGS - Produktion Sjælland - Kalundborg",
        tasks: {
            XYpEJ2YOrgARzTbumxqH: {
                completeTime: { _seconds: 1737036533, _nanoseconds: 813000000 },
                createTime: "2025-01-16T12:47:41.175Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                        value: 51733,
                    },
                ],
                description:
                    "Registerering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "XYpEJ2YOrgARzTbumxqH",
                isCompleted: true,
                order: 0,
            },
        },
    },
    {
        auditId: "PRwLDLFq1HwX8quO2hnn",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "true",
        completeTime: "1737038310",
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Rærup - Miljømål",
        orgId: "Rpk1yz4tTiaBAndayv1F",
        subGroup: "RGS Nordic A/S - Vest - Rærup",
        tasks: {
            pCIMZ9KsW9JFaOwnUPOE: {
                completeTime: { _seconds: 1737038309, _nanoseconds: 286000000 },
                createTime: "2025-01-16T13:08:54.196Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                        value: 118,
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "pCIMZ9KsW9JFaOwnUPOE",
                isCompleted: true,
                order: 0,
            },
        },
    },
    {
        auditId: "LHvoWRyWshpNMLgO6bAS",
        templateTitle: "Årsforbrug af el",
        auditTemplateCategory: "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "true",
        completeTime: "1737038528",
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Aalborg Rørdalsvej - Miljømål",
        orgId: "dluEPaDAgKhpIwTMdkI2",
        subGroup: "RGS Nordic A/S - Vest - Aalborg Rørdalsvej",
        tasks: {
            wfuEsZEZvE6KLoU7yjIo: {
                completeTime: { _seconds: 1737038526, _nanoseconds: 257000000 },
                createTime: "2025-01-16T13:09:58.965Z",
                customFields: [
                    {
                        description: "Elforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "energy",
                        unit: "kwh",
                        value: 6384,
                    },
                ],
                description:
                    "Registrering af samlet elforbrug for sitet 1. jan til 31. dec",
                id: "wfuEsZEZvE6KLoU7yjIo",
                isCompleted: true,
                order: 0,
            },
        },
    },
    {
        auditId: "RzldTJt5osJx5NGPR0dg",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "true",
        completeTime: "1737038056",
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Aalborg Savannevej - Miljømål",
        orgId: "EmPyM8IbPOMIVGixxABZ",
        subGroup: "RGS Nordic A/S - Vest - Aalborg Savannevej",
        tasks: {
            bHdeGtqjhCFaiLighPoT: {
                completeTime: { _seconds: 1737038054, _nanoseconds: 996000000 },
                createTime: "2025-01-16T13:13:30.605Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                        value: 112175,
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "bHdeGtqjhCFaiLighPoT",
                isCompleted: true,
                order: 0,
            },
        },
    },
    {
        auditId: "B8eF19IDIKuCdan7Zgs7",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Hjøllund - Miljømål",
        orgId: "sUcZaGNTDqAp3uesMw2h",
        subGroup: "RGS Nordic A/S - Vest - Hjøllund",
        tasks: {
            "0z0cIsgL0ajlI5jqw6IV": {
                createTime: "2025-01-16T13:04:23.510Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "0z0cIsgL0ajlI5jqw6IV",
                order: 0,
            },
        },
    },
    {
        auditId: "6DsW5Iwy0wrvKdQ3d7fC",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Fasterholt - Miljømål",
        orgId: "I6fWCNBBh7prGo3ewrOv",
        subGroup: "RGS Nordic A/S - Vest - Fasterholt",
        tasks: {
            Agpj7UAO3d2psJUKPpZv: {
                createTime: "2025-01-16T13:02:03.055Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "Agpj7UAO3d2psJUKPpZv",
                order: 0,
            },
        },
    },
    {
        auditId: "iSggS7bd5CWeWsbmzmrE",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: null,
        hasOnHoldTasks: null,
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "false",
        completeTime: null,
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Glatved/Balle - Miljømål",
        orgId: "BNyOc95aqFkA9Wlyq9Mz",
        subGroup: "RGS Nordic A/S - Vest - Glatved/Balle",
        tasks: {
            NHqjZhrB1rDlOisOFEZn: {
                createTime: "2025-01-16T13:03:11.967Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "NHqjZhrB1rDlOisOFEZn",
                order: 0,
            },
        },
    },
    {
        auditId: "n2qrhWKhzYlBnmlCR2RP",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "RuXfdQ77eGHqAsQSHK5m",
        isCompleted: "true",
        completeTime: "1737036457",
        dueDate: "1735923600",
        orgTitle: "RGS - Produktion Sjælland - Kalundborg - Miljømål",
        orgId: "NRiWvZz6lBLjvvPpR5vY",
        subGroup: "RGS - Produktion Sjælland - Kalundborg",
        tasks: {
            TG2oc4UbbljsjD8sxyXk: {
                completeTime: { _seconds: 1737036455, _nanoseconds: 653000000 },
                createTime: "2025-01-16T12:48:03.861Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                        value: 1725568,
                    },
                ],
                description:
                    "Registerering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "TG2oc4UbbljsjD8sxyXk",
                isCompleted: true,
                order: 0,
            },
        },
    },
    {
        auditId: "n55DiAoya3XCHrsH3atV",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "true",
        completeTime: "1737038379",
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Rærup - Miljømål",
        orgId: "Rpk1yz4tTiaBAndayv1F",
        subGroup: "RGS Nordic A/S - Vest - Rærup",
        tasks: {
            stsrzfDySdx29RLAJCEX: {
                completeTime: { _seconds: 1737038373, _nanoseconds: 178000000 },
                createTime: "2025-01-16T13:09:16.799Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                        value: 2100,
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "stsrzfDySdx29RLAJCEX",
                isCompleted: true,
                order: 0,
            },
        },
    },
    {
        auditId: "l7D1pqi75vNrOXqCjOKu",
        templateTitle: "Årsforbrug af diesel",
        auditTemplateCategory: "RGS_0512b56b-e5b7-419d-89c3-08e47bd3c01a",
        needsResolution: "false",
        hasOnHoldTasks: "false",
        groupId: "Gua3ROcmn9CBLIGSpOVn",
        isCompleted: "true",
        completeTime: "1737038544",
        dueDate: "1735923600",
        orgTitle: "RGS Nordic A/S - Vest - Aalborg Rørdalsvej - Miljømål",
        orgId: "dluEPaDAgKhpIwTMdkI2",
        subGroup: "RGS Nordic A/S - Vest - Aalborg Rørdalsvej",
        tasks: {
            SBQVONDmyg0UnKvKwcxZ: {
                completeTime: { _seconds: 1737038543, _nanoseconds: 755000000 },
                createTime: "2025-01-16T13:10:21.739Z",
                customFields: [
                    {
                        description: "Dieselforbrug for året",
                        fieldType: "number",
                        required: true,
                        type: "volume",
                        unit: "liters",
                        value: 112175,
                    },
                ],
                description:
                    "Registrering af samlet dieselforbrug for sitet 1. jan til 31. dec",
                id: "SBQVONDmyg0UnKvKwcxZ",
                isCompleted: true,
                order: 0,
            },
        },
    },
];
// .map(audit => {
//     const tasks = JSON.parse(audit.tasks);
//     return {
//         ...audit,
//         tasks,
//     };
// });

function groupByMonth(data: any[]) {
    const result = {};

    data.forEach(item => {
        const dueDateSeconds = item.audit.dueDate._seconds;
        const dueDate = new Date(dueDateSeconds * 1000);
        const month =
            dueDate.getFullYear() + "-" + String(dueDate.getMonth() + 1).padStart(2, "0");
        // @ts-ignore
        if (!result[month]) {
            // @ts-ignore
            result[month] = [];
        }

        // Check for duplicate entries
        if (
            // @ts-ignore
            !result[month].some(entry => JSON.stringify(entry) === JSON.stringify(item))
        ) {
            // @ts-ignore
            result[month].push({
                audit: item.audit,
                orgTitle: item.orgTitle,
                auditId: item.auditId,
            });
        }
    });

    return result;
}

function sumCustomFields(data: any[]) {
    const groupedSums = {};

    data.forEach(item => {
        const audit = item.audit;
        const category = audit.auditTemplateId;

        Object.keys(audit.tasks).forEach(taskKey => {
            const task = audit.tasks[taskKey];
            // @ts-ignore
            task.customFields?.forEach(field => {
                const type = field.type;
                const unit = field.unit;
                const combined = `${type}.${unit}`;
                const value = field.value || 0;

                // @ts-ignore
                if (!groupedSums[category]) {
                    // @ts-ignore
                    groupedSums[category] = {};
                }
                // @ts-ignore
                if (!groupedSums[category][combined]) {
                    // @ts-ignore
                    groupedSums[category][combined] = 0;
                }
                // @ts-ignore
                groupedSums[category][combined] += value;
            });
        });
    });

    return groupedSums;
}

function aggregateTaskValuesUsingReduce(dataArray: any[]) {
    const aggregation: any = {};

    dataArray.forEach((item, index) => {
        const { audit, org_title } = item;

        // Validate org_title
        if (!org_title) {
            console.warn(`Item at index ${index} is missing 'org_title'. Skipping.`);
            return;
        }

        // Validate audit and auditTemplateCategory
        if (!audit || !audit.auditTemplateId) {
            console.warn(
                `Item at index ${index} is missing 'audit' or 'auditTemplateId'. Skipping.`,
            );
            return;
        }

        const { auditTemplateId, tasks } = audit;

        // Initialize org_title in aggregation if not present
        if (!aggregation[org_title]) {
            aggregation[org_title] = {};
        }

        // Initialize auditTemplateId within org_title if not present
        if (!aggregation[org_title][auditTemplateId]) {
            aggregation[org_title][auditTemplateId] = {};
        }

        // Validate tasks
        if (!tasks || typeof tasks !== "object") {
            console.warn(
                `Item at index ${index} with org_title '${org_title}' and auditTemplateId '${auditTemplateId}' has invalid or missing 'tasks'. Skipping.`,
            );
            return;
        }

        // Iterate through each task
        Object.entries(tasks).forEach(([taskId, task]: any) => {
            if (!task || !task.customFields || !Array.isArray(task.customFields)) {
                console.warn(
                    `Task '${taskId}' in org_title '${org_title}' and auditTemplateId '${auditTemplateId}' is missing or has invalid 'customFields'. Skipping.`,
                );
                return;
            }

            // Sum 'value' fields in customFields
            const sumValues = task.customFields.reduce(
                (sum: any, field: any, fieldIndex: any) => {
                    if (field && typeof field.value === "number") {
                        return sum + field.value;
                    } else if (field && typeof field.value === "string") {
                        const parsedValue = parseFloat(field.value);
                        if (!isNaN(parsedValue)) {
                            return sum + parsedValue;
                        } else {
                            console.warn(
                                `Field at index ${fieldIndex} in task '${taskId}' of org_title '${org_title}' and auditTemplateId '${auditTemplateId}' has a non-numeric 'value': ${field.value}. Ignoring.`,
                            );
                            return sum;
                        }
                    } else {
                        console.warn(
                            `Field at index ${fieldIndex} in task '${taskId}' of org_title '${org_title}' and auditTemplateId '${auditTemplateId}' is missing a 'value' or has an unsupported type.`,
                        );
                        return sum;
                    }
                },
                0,
            );

            // Initialize taskId within auditTemplateId if not present
            if (!aggregation[org_title][auditTemplateId][taskId]) {
                aggregation[org_title][auditTemplateId][taskId] = 0;
            }

            // Add the sumValues to the corresponding group
            aggregation[org_title][auditTemplateId][taskId] += sumValues;

            console.log(
                `Added ${sumValues} to task '${taskId}' under org_title '${org_title}' and auditTemplateId '${auditTemplateId}'. Total now: ${aggregation[org_title][auditTemplateId][taskId]}`,
            );
        });
    });

    return aggregation;
}

function getAllUniqueCategories(data: any[]): string[] {
    const uniqueCategories = new Set();

    data.forEach(item => {
        const audit = item;

        if (audit && audit.auditTemplateId) {
            uniqueCategories.add(audit.auditTemplateId);
        }
    });

    // @ts-ignore
    return Array.from(uniqueCategories);
}

function getSummedValueFromAudit(audit: any) {
    if (!audit || !audit?.tasks) return {};
    let sum: { [unit: string]: number } = {};
    Object.entries(audit?.tasks).forEach(([_, task]: any) => {
        const fieldsByType = groupBy(task.customFields, "unit");
        console.log({ fieldsByType });

        Object.entries(fieldsByType).forEach(([unit, fields]: any) => {
            sum[unit] = fields.reduce(
                (acc: number, field: any) => acc + (field.value || 0),
                0,
            );
        });

        // task.customFields?.forEach((field: any) => {
        //     sum += field.value || 0;
        // });
    });
    console.log({ sum });

    return sum;
}
