import { Select } from "antd";
import { If } from "modules/inventory/types";
import { IOrg } from "modules/orgs/interfaces";
import { useSelector } from "react-redux";
import { AppState } from "store";

export default function OrgsSelect({
    orgs,
    groupIds,
    orgIds,
    selectedIds,
    hasGroupAccess,
    mode,
    ...rest
}: {
    orgs: ({ id: string; title: string } | IOrg)[];
    groupIds: string[];
    orgIds: If<typeof mode, "multiple", string[], string>;
    selectedIds: (orgIds: If<typeof mode, "multiple", string[], string>) => void;
    hasGroupAccess?: boolean;
    mode?: "multiple" | "tags";
    [key: string]: any;
}) {
    const groupMap = useSelector((state: AppState) => state.groups.groupsMap);
    const groupOptions = groupIds.map(groupId => ({
        label: groupMap[groupId]?.title,
        value: groupId,
    }));
    const options = orgs
        .sort((a, b) =>
            a.title?.toLowerCase().localeCompare(b.title?.toLowerCase(), "da", {
                sensitivity: "base",
                numeric: true,
            }),
        )
        .map(org => ({ label: org.title, value: org.id }));

    return (
        <Select
            {...rest}
            mode={mode}
            style={{ width: "100%" }}
            value={orgIds}
            onChange={(newValue: If<typeof mode, "multiple", string[], string>) => {
                selectedIds(newValue);
            }}
            placeholder={"Vælg afdelinger..."}
            maxTagCount={"responsive"}>
            {hasGroupAccess && groupOptions.length > 0 && (
                <Select.OptGroup key={"group"} label={"Grupper"}>
                    {groupOptions.map(group => (
                        <Select.Option key={group.value} value={group.value}>
                            {group.label}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )}

            {/* <Select.OptGroup key={"site"} label={"Grupper"}>
                {[
                    {
                        label: "Kontrolplan",
                        value: "kontrolplan",
                    },
                    {
                        label: "Sikkerhed",
                        value: "sikkerhed",
                    },
                    { label: "Miljø", value: "miljømål" },
                ].map(group => (
                    <Select.Option key={group.value} value={group.value}>
                        {group.label}
                    </Select.Option>
                ))}
            </Select.OptGroup> */}

            {options.length > 0 && (
                <Select.OptGroup key={"org"} label={"Afdelinger"}>
                    {options.map(org => (
                        <Select.Option key={org.value} value={org.value}>
                            {org.label}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )}
        </Select>
    );
}
