import React, { useState } from "react";
import {
    Form,
    Select,
    InputNumber,
    DatePicker,
    TimePicker,
    Checkbox,
    Button,
    Row,
    Col,
    Space,
} from "antd";
import { RRule } from "rrule";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

const frequencyOptions = [
    { label: "Dag", value: "Daily" },
    { label: "Uge", value: "Weekly" },
    { label: "Måned", value: "Monthly" },
    { label: "År", value: "Yearly" },
];

const weekdays = [
    { label: "Man", value: 1 },
    { label: "Tir", value: 2 },
    { label: "Ons", value: 3 },
    { label: "Tor", value: 4 },
    { label: "Fre", value: 5 },
    { label: "Lør", value: 6 },
    { label: "Søn", value: 7 },
];

const monthOptions = [
    { label: "Jan", value: 1 },
    { label: "Feb", value: 2 },
    { label: "Mar", value: 3 },
    { label: "Apr", value: 4 },
    { label: "Maj", value: 5 },
    { label: "Jun", value: 6 },
    { label: "Jul", value: 7 },
    { label: "Aug", value: 8 },
    { label: "Sep", value: 9 },
    { label: "Okt", value: 10 },
    { label: "Nov", value: 11 },
    { label: "Dec", value: 12 },
];

export default function RepeatOptionsForm({
    // interval,
    frequency,
    startDate,
    timeOption,
}: {
    interval: number;
    frequency: "Daily" | "Weekly" | "Monthly" | "Yearly";
    startDate: Dayjs;
    timeOption: Dayjs;
}) {
    const { t } = useTranslation();
    // const [form] = Form.useForm();
    console.log({ startDate });

    const displayDate = () => {
        if (frequency === "Weekly" || frequency === "Daily") {
            return "kl. " + t("general.time", { date: timeOption.toDate() });
        }
        if (frequency === "Monthly" || frequency === "Yearly") {
            return (
                "d. " +
                t("general.getDate", { date: startDate.toDate() }) +
                " kl. " +
                t("general.time", { date: timeOption.toDate() })
            );
        }
        return "";
    };

    const [rrule, setRrule] = useState<RRule>();

    // const onFinish = (values: any) => {
    //     const rule = createGoogleRepeatRule({

    //     });
    //     // alert(JSON.stringify(rule.all()));
    //     setRrule(rule);
    // };
    // frequency: 1,
    //                             interval: 1,
    //                             dayOfMonth: 1,
    //                             monthOfYear: 1,
    //                             date: selectedValue,
    //                             hour: ,
    //                             min: values.time?.minute() || 0,
    //                             dayRepeat: values.dayRepeat || [],
    //                             until: values.until?.toDate(),
    //                             count: values.count,

    return (
        // <Form
        //     form={form}
        //     onFinish={onFinish}
        //     layout="vertical"
        //     initialValues={{ frequency: "Daily", interval: 1 }}>
        <>
            <Space style={{ display: "flex", alignItems: "center" }}>
                Gentages{displayDate()}hver
                <Form.Item label="" name="interval" initialValue={1}>
                    <InputNumber
                        style={{ width: 160 }}
                        min={1}
                        addonAfter={
                            <Form.Item
                                style={{ margin: 0 }}
                                label=""
                                name="frequency"
                                initialValue={"Daily"}>
                                <Select
                                    style={{ width: 100 }}
                                    options={frequencyOptions}
                                />
                            </Form.Item>
                        }
                    />
                </Form.Item>
            </Space>

            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                    const freq = getFieldValue("frequency");

                    return (
                        <>
                            {freq === "Weekly" && (
                                <Form.Item
                                    label="Ugedage"
                                    name="dayRepeat"
                                    style={{ marginTop: 8 }}>
                                    <Checkbox.Group
                                        options={weekdays.map(d => ({
                                            label: d.label,
                                            value: d.value,
                                        }))}
                                    />
                                </Form.Item>
                            )}
                            {/* {freq === "Monthly" && (
                                <Form.Item label="Day of Month" name="dayOfMonth">
                                    <InputNumber min={1} max={31} />
                                </Form.Item>
                            )} */}
                            {freq === "Yearly" && (
                                <>
                                    <Form.Item
                                        label="Kun i månederne"
                                        name="monthOfYear"
                                        style={{ marginTop: 8 }}>
                                        <Select
                                            mode="multiple"
                                            placeholder="Vælg en eller flere måneder"
                                            options={monthOptions}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                    {/* <Form.Item label="Day of Month" name="dayOfMonth">
                                        <InputNumber min={1} max={31} />
                                    </Form.Item> */}
                                </>
                            )}
                        </>
                    );
                }}
            </Form.Item>

            {/* <Form.Item label="Start Date" name="date" initialValue={startDate}>
                <DatePicker locale={da} />
            </Form.Item>

            <Form.Item label="Start Time" name="time">
                <TimePicker format="HH:mm" />
            </Form.Item>

            <Form.Item label="Until" name="until">
                <DatePicker locale={da} />
            </Form.Item> */}

            {/* <Form.Item label="Count (occurrences)" name="count" initialValue={1}>
                <InputNumber min={1} />
            </Form.Item> */}

            {/* <Button type="primary" htmlType="submit">
                Generate RRule
            </Button> */}
        </>
    );
}
// import React, { useState } from "react";
// import {
//     Button,
//     Modal,
//     Form,
//     InputNumber,
//     Select,
//     Radio,
//     DatePicker,
//     Row,
//     Col,
//     Tooltip,
// } from "antd";
// import { Dayjs } from "dayjs";
// import { useTranslation } from "react-i18next";
// import { StyleGuide } from "styles/StyleGuide";
// import { generateCronExpression, parseCron } from "./utils";
// // import "antd/dist/antd.css"; // Import Ant Design CSS

// const { Option } = Select;
// const { Group: RadioGroup } = Radio;

// type DayRepeat = 1 | 2 | 3 | 4 | 5 | 6 | 7;

// type State = {
//     frequency: string;
//     interval: number;
//     dayOfMonth: string;
//     monthOfYear: string;
//     dayRepeat: DayRepeat[];
//     // daysOfWeek: number[];
//     // endCondition: string;
//     // occurrences: number;
//     // endDate: Dayjs | null;
// };

// function stateToCron(state: State, hour: number, min: number): string {
//     const { frequency, interval, dayOfMonth, monthOfYear, dayRepeat } = state;

//     switch (frequency) {
//         case "Daily":
//             return `${min} ${hour} */${interval} * *`;
//         case "Weekly":
//             const daysOfWeek = dayRepeat.join(",");
//             return `${min} ${hour} * * ${daysOfWeek}/${interval}`;
//         case "Monthly":
//             return `${min} ${hour} ${dayOfMonth} */${interval} *`;
//         case "Yearly":
//             return `${min} ${hour} ${dayOfMonth} ${monthOfYear} *`;
//         default:
//             throw new Error("Invalid frequency");
//     }
// }

// // Usage example:
// const CustomRecurrencePopup = ({ time }: { time: Dayjs }) => {
//     console.log({ time });

//     const { t } = useTranslation();
//     const min = time.get("minute");
//     const hour = time.get("hour");
//     const [isVisible, setIsVisible] = useState(false);
//     const [recurrence, setRecurrence] = useState<State>({
//         frequency: "Weekly",
//         interval: 1,
//         dayOfMonth: "1",
//         monthOfYear: "January",
//         dayRepeat: [] as DayRepeat[],
//         // daysOfWeek: [],
//         // endCondition: "Never",
//         // occurrences: 10,
//         // endDate: null,
//     });

//     const showModal = () => {
//         setIsVisible(true);
//     };

//     const handleOk = () => {
//         setIsVisible(false);
//         console.log(recurrence); // Process data here or pass to parent component
//     };

//     const handleCancel = () => {
//         setIsVisible(false);
//     };

//     const onRecurrenceChange = (
//         value: string | number | number[] | Dayjs | null,
//         key: string,
//     ) => {
//         setRecurrence({ ...recurrence, [key]: value });
//     };

//     const getDayOfWeek = (number: number) => {
//         const daysOfWeek = [
//             "date.monday.letter",
//             "date.tuesday.letter",
//             "date.wednesday.letter",
//             "date.thursday.letter",
//             "date.friday.letter",
//             "date.saturday.letter",
//             "date.sunday.letter",
//         ];
//         return daysOfWeek[number - 1];
//     };

//     const cron = stateToCron(recurrence, hour, min);

//     return (
//         <>
//             <div>{cron}</div>
//             {/* <div>{parseCron(cron)}</div> */}
//             {/* <Button type="primary" onClick={showModal}>
//                 Set Custom Recurrence
//             </Button> */}
//             {/* <Modal
//                 title="Custom Recurrence"
//                 visible={isVisible}
//                 onOk={handleOk}
//                 onCancel={handleCancel}
//                 width={600}> */}
//             <Form>
//                 <Row style={{ paddingTop: 16 }}>
//                     <Col md={6} style={{ paddingTop: 4, alignItems: "center" }}>
//                         Gentages hver:
//                     </Col>
//                     <Col md={3}>
//                         <Form.Item label="" style={{ marginRight: "16px" }}>
//                             <InputNumber
//                                 style={{ width: 20 }}
//                                 min={1}
//                                 defaultValue={12}
//                                 onChange={value => onRecurrenceChange(value, "interval")}
//                             />
//                         </Form.Item>
//                     </Col>
//                     <Col md={12}>
//                         <Form.Item label="">
//                             <Select
//                                 style={{ width: 150 }}
//                                 defaultValue="Weekly"
//                                 onChange={value =>
//                                     onRecurrenceChange(value, "frequency")
//                                 }>
//                                 <Option value="Daily">Dag(e)</Option>
//                                 <Option value="Weekly">Uge(r)</Option>
//                                 <Option value="Monthly">Måned</Option>
//                                 <Option value="Yearly">år</Option>
//                             </Select>
//                         </Form.Item>
//                     </Col>
//                 </Row>
//                 {recurrence.frequency === "Weekly" && (
//                     <Row style={{ paddingTop: 16, paddingBottom: 16 }}>
//                         <Col
//                             md={6}
//                             style={{
//                                 paddingTop: 4,
//                                 paddingBottom: 4,
//                                 alignItems: "center",
//                             }}>
//                             Gentages:
//                         </Col>
//                         <Col md={18}>
//                             <Form.Item label="">
//                                 {[1, 2, 3, 4, 5, 6, 7].map(day => {
//                                     const dayOfWeek = getDayOfWeek(day); // Returns 'Monday'
//                                     // @ts-ignore
//                                     const isSelected = recurrence.dayRepeat.includes(day);
//                                     console.log({ dayRepeat: recurrence.dayRepeat });

//                                     return (
//                                         <Tooltip
//                                             title={t(
//                                                 dayOfWeek.replace("letter", "long"),
//                                             )}>
//                                             <Button
//                                                 size="small"
//                                                 key={day}
//                                                 style={{
//                                                     backgroundColor: !isSelected
//                                                         ? StyleGuide.palette.grey200
//                                                         : undefined,
//                                                     fontSize: 12,
//                                                     marginRight: 4,
//                                                 }}
//                                                 type={isSelected ? "primary" : "dashed"}
//                                                 shape="circle"
//                                                 onClick={() => {
//                                                     const updatedDays = isSelected
//                                                         ? recurrence.dayRepeat.filter(
//                                                               d => d !== day,
//                                                           )
//                                                         : [...recurrence.dayRepeat, day];
//                                                     console.log({ updatedDays, day });

//                                                     onRecurrenceChange(
//                                                         updatedDays,
//                                                         "dayRepeat",
//                                                     );
//                                                 }}>
//                                                 {t(dayOfWeek)}
//                                             </Button>
//                                         </Tooltip>
//                                     );
//                                 })}
//                             </Form.Item>
//                         </Col>
//                     </Row>
//                 )}
//                 {/* Add other conditional fields based on frequency like dayOfMonth, monthOfYear */}
//                 {/* <Form.Item label="Ends">
//                     <RadioGroup
//                         defaultValue="Never"
//                         onChange={e =>
//                             onRecurrenceChange(e.target.value, "endCondition")
//                         }>
//                         <Radio value="Never">Never</Radio>
//                         <Radio value="After">After</Radio>
//                         <Radio value="On">On</Radio>
//                     </RadioGroup>
//                 </Form.Item>
//                 {recurrence.endCondition === "After" && (
//                     <Form.Item label="Occurrences">
//                         <InputNumber
//                             min={1}
//                             onChange={value => onRecurrenceChange(value, "occurrences")}
//                         />
//                     </Form.Item>
//                 )}
//                 {recurrence.endCondition === "On" && (
//                     <Form.Item label="End Date">
//                         <DatePicker
//                             onChange={value => onRecurrenceChange(value, "endDate")}
//                         />
//                     </Form.Item>
//                 )} */}
//             </Form>
//             {/* </Modal> */}
//         </>
//     );
// };

// export default CustomRecurrencePopup;
