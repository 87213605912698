import React from "react";
import { ResponsiveCalendar } from "@nivo/calendar";
import { format, isSameDay } from "date-fns";
import { Intl } from "general/enums";
import { locales } from "languages/i18n";

type AuditData = {
    completeTime: string; // Unix timestamp as a string
    dueDate: string; // Unix timestamp as a string
    templateTitle: string;
    subGroup: string;
};

type CalendarChartProps = {
    audits: AuditData[];
    year: string;
};

const NCalendarChart: React.FC<CalendarChartProps> = ({ audits, year }) => {
    // Prepare the data for the calendar chart
    console.log({ audits });

    const calendarData = audits.reduce((acc, audit) => {
        const date = new Date(Number(audit.dueDate) * 1000); // Convert Unix timestamp to Date
        const dateString = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD

        // Update count for each day
        const existingEntry = acc.find(entry => entry.day === dateString);
        if (existingEntry) {
            existingEntry.value += 1;
        } else {
            acc.push({ day: dateString, value: 1 });
        }
        return acc;
    }, [] as { day: string; value: number }[]);

    return (
        <ResponsiveCalendar
            monthLegend={(year, month) => {
                const date = new Date(year, month, 1);
                return format(date, "MMMM", { locale: locales.da });
            }}
            data={calendarData}
            from={`${year}-01-01`}
            to={`${year}-12-31`}
            emptyColor="#eeeeee"
            colors={["#d6e685", "#8cc665", "#44a340", "#1e6823", "#004d00"]}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            yearSpacing={40}
            monthBorderColor="#333333"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            monthBorderWidth={2}
            monthLegendOffset={10}
            // valueFormat=".2f"
            // legendFormat={value => value + "O"}
            // legends={[
            //     {
            //         anchor: "top",
            //         direction: "row",
            //         translateY: 36,
            //         itemCount: 4,
            //         itemWidth: 42,
            //         itemHeight: 36,
            //         itemsSpacing: 14,
            //         itemDirection: "right-to-left",
            //     },
            // ]}
            role="application"
            // @ts-ignore
            ariaLabel="Audit Completion Calendar Heatmap"
            tooltip={({ day, value }) => {
                console.log({ day, value, calendarData });
                const auditsThisDay = audits.filter(a =>
                    isSameDay(new Date(day), new Date(Number(a.dueDate) * 1000)),
                );

                return (
                    <div
                        key={day}
                        style={{
                            padding: 16,
                            backgroundColor: "#fff",
                            borderRadius: 8,
                            boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
                        }}>
                        <strong>
                            {day}: {value} opgaver
                        </strong>
                        {auditsThisDay?.map(a => (
                            <div>
                                {a?.templateTitle} ({a?.subGroup})
                            </div>
                        ))}
                    </div>
                );
            }}
        />
    );
};

export default NCalendarChart;
