import { useEffect, useState } from "react";
import {
    Form,
    Select,
    Row,
    Col,
    Button,
    Modal,
    TimePicker,
    Checkbox,
    DatePicker,
    Popover,
    Tooltip,
    Input,
    Divider,
} from "antd";
import { generateRecurringAudits } from "../../general/dates";
import { Intl, QUICK_TASK, RecurringOptions } from "../../general/enums";
import { format, getHours, getMinutes, setHours, setMinutes, setSeconds } from "date-fns";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks";
import dayjs from "dayjs";
import range from "lodash.range";
import { Dayjs } from "dayjs";
import { FormResponsibleSelect } from "components/ResponsibleSelect";
import { AuditSchedule, AuditSourceType } from "modules/auditSchedule/types";
import { InfoCircleOutlined } from "@ant-design/icons";
import da from "antd/es/date-picker/locale/da_DK";
import { getLocale } from "general/intl";
import { StyleGuide } from "styles/StyleGuide";
import RecurringOptionsSelect from "./RecurringOptionsSelect";
import SelectTemplate from "./SelectTemplate";
import AlreadyScheduled from "./AlreadyScheduled";
import RepeatOptionsForm from "./RecurringOptions";
import { createGoogleRepeatRule } from "./utils";
import { RRule } from "rrule";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    wrapperCol: { span: 24 },
};
function getDateSetTime(selectedDate: Date, timeOption: Dayjs): Date {
    const dateTime = timeOption.toDate();
    const hours = getHours(dateTime);
    const minutes = getMinutes(dateTime);
    const toDate = selectedDate;
    const hoursSet = setHours(toDate, hours);
    const minutesSet = setMinutes(hoursSet, minutes);
    const secondsSet = setSeconds(minutesSet, 0);
    return secondsSet;
}
interface Props {
    // open: boolean;
    selectedValue: any;
    handleClose: VoidFunction;
    addAuditSchedule: (data: {
        // templateTitle: string;
        // templateNote: string;
        // guide: AuditGuide | null;
        taskTitle?: string; // QUICK TASK
        templateId: string;
        recurringOption: AuditSchedule["recurringOption"];
        dueDate: Date;
        notifyDaysAfterDueDate?: AuditSchedule["notifyDaysAfterDueDate"];
        startDaysBeforeDueDate?: AuditSchedule["startDaysBeforeDueDate"];
        responsible: AuditSchedule["responsible"];
        auditSourceType?: AuditSourceType;
        repeatRule?: string;
        // auditTemplateCategory?: string | null;
    }) => void;
}

const defaultTime = "18:00";
export default function ScheduleModal({
    // open,
    selectedValue,
    addAuditSchedule,
    handleClose,
}: Props) {
    const { t } = useTranslation();
    const [isMultiDay, setisMultiDay] = useState(false);
    const [getNotificationAfter, setGetNotificationAfter] = useState(false);
    const { intl, groupAuditTemplates, auditTemplates } = useAppSelector(state => ({
        auditTemplates: state.auditTemplates.auditTemplates,
        groupAuditTemplates: state.groupAuditTemplates.groupAuditTemplates,
        intl: state.user.userDoc.intl,
    }));
    const [form] = Form.useForm();
    const templateId = Form.useWatch("templateId", { form, preserve: true });
    const recurringOption = Form.useWatch("recurringOption", { form, preserve: true });
    const dueDate = Form.useWatch("dueDate", { form, preserve: true });
    const timeOption = Form.useWatch("timeOption", { form, preserve: true });
    const allValues = Form.useWatch([], form);
    console.log({ allValues });

    useEffect(() => {
        if (!selectedValue) return;
        form.setFieldsValue({ dueDate: dayjs(selectedValue) });
    }, [selectedValue?.getTime()]);

    useEffect(() => {
        form.validateFields();
    }, []);
    const members = useAppSelector(state => state.orgs.currentOrg.members);

    const currentTemplate = auditTemplates.find(t => t.id === templateId);
    const currentGroupTemplate = groupAuditTemplates.find(t => t.id === templateId);

    const customRule =
        dueDate &&
        createGoogleRepeatRule({
            frequency: allValues?.frequency || "Daily",
            interval: allValues?.interval || 1,
            dayOfMonth: dayjs(dueDate?.toDate()).date() || 1,
            monthOfYear: allValues?.monthOfYear || [1],
            date: dueDate?.toDate(),
            hour: allValues?.timeOption?.hour() || 18,
            min: allValues?.timeOption?.minute() || 0,
            dayRepeat: allValues?.dayRepeat || [],
            until: undefined, //new Date("2026-01-01"),
            count: 1,
        });

    const handleSubmit = (values: any) => {
        const {
            templateId,
            recurringOption,
            timeOption,
            notifyDaysAfterDueDate,
            startDateBeforeAuditDueDate,
        } = values;

        const isQuickTask = templateId === QUICK_TASK.OPTION;

        console.log({ currentGroupTemplate, templateId, currentTemplate });
        if (!currentTemplate && !currentGroupTemplate && !isQuickTask) {
            return alert("Please select a template!");
        }

        if (RecurringOptions.CUSTOM === recurringOption && !customRule) {
            return alert("Please select a frequency!");
        }

        const displayName =
            members[values.responsibleId]?.name || members[values.responsibleId]?.email;

        const taskDueDate = getDateSetTime(dueDate.toDate(), timeOption);
        addAuditSchedule({
            // templateTitle: currentTemplate.title,
            // templateNote: currentTemplate.note || "",
            taskTitle: isQuickTask ? values.quickTaskTitle : undefined,
            templateId,
            recurringOption,
            dueDate: taskDueDate,
            responsible: values.responsibleId
                ? {
                      id: values.responsibleId,
                      displayName,
                  }
                : null,
            // guide: currentTemplate.guide ?? null,
            startDaysBeforeDueDate: isMultiDay ? startDateBeforeAuditDueDate : undefined,
            notifyDaysAfterDueDate: getNotificationAfter
                ? notifyDaysAfterDueDate
                : undefined,
            auditSourceType: currentGroupTemplate
                ? AuditSourceType.GROUP_TEMPLATE
                : undefined,
            repeatRule:
                recurringOption === RecurringOptions.CUSTOM
                    ? customRule.toString()
                    : undefined,
            // auditTemplateCategory: currentTemplate?.auditTemplateCategory ?? null,
        });

        form.resetFields();
        handleClose();
    };

    const sholdDisplayAlreadyScheduled = templateId && templateId !== QUICK_TASK.OPTION;

    return (
        <Modal
            className="schedule-modal"
            width={sholdDisplayAlreadyScheduled ? "70%" : "50%"}
            style={{
                position: "relative",
            }}
            styles={{
                body: {
                    padding: 0,
                },
            }}
            open={Boolean(selectedValue)}
            onCancel={handleClose}
            footer={[]}>
            {selectedValue && (
                <Row>
                    <Col
                        span={sholdDisplayAlreadyScheduled ? 18 : 24}
                        style={{ padding: 24 }}>
                        <h1>{t("audit.schedule.headline")}</h1>

                        <Form
                            form={form}
                            layout="inline"
                            initialValues={{
                                dueDate: dayjs(selectedValue),
                            }}
                            onFinish={handleSubmit}>
                            <Row style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}>
                                    <FormItem
                                        name="templateId"
                                        {...formItemLayout}
                                        style={{ width: "100%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select a template!",
                                            },
                                        ]}>
                                        <SelectTemplate />
                                    </FormItem>
                                </Col>
                            </Row>
                            {templateId === QUICK_TASK.OPTION && (
                                <Row style={{ width: "100%", marginBottom: 16 }}>
                                    <Divider style={{ margin: 0, marginBottom: 16 }} />
                                    <Col md={24}>
                                        <FormItem
                                            name="quickTaskTitle"
                                            {...formItemLayout}
                                            style={{ width: "100%" }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Skriv navnet på opgaven",
                                                },
                                            ]}>
                                            <Input
                                                autoFocus
                                                // prefix={
                                                //     <UserOutlined
                                                //         style={{
                                                //             color: "rgba(0,0,0,.25)",
                                                //         }}
                                                //     />
                                                // }
                                                size="large"
                                                placeholder="Opgavens navn/beskrivelse"
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            )}

                            <Row style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}>{t("audit.schedule.datelabel")}</Col>
                                <Col md={18}>
                                    <FormItem
                                        name="dueDate"
                                        {...formItemLayout}
                                        // style={{ width: "100%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select a date!",
                                            },
                                        ]}>
                                        <DatePicker
                                            placeholder="Vælg dato"
                                            format={"dddd Do MMM YYYY - (Uge ww)"}
                                            style={{ width: "100%" }}
                                            locale={da}
                                        />
                                    </FormItem>
                                </Col>
                                <Col md={6}>
                                    <FormItem
                                        name="timeOption"
                                        style={{ width: "100%" }}
                                        initialValue={dayjs(defaultTime, "HH:mm")}>
                                        <TimePicker
                                            format={"HH:mm"}
                                            minuteStep={5}
                                            allowClear={false}
                                            style={{ width: "100%" }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}>{t("audit.schedule.repeatlabel")}</Col>
                                <Col md={12}>
                                    <FormItem
                                        name="recurringOption"
                                        // {...formItemLayoutTwo}
                                        // style={{ width: "100%" }}
                                        initialValue={RecurringOptions.NEVER}>
                                        <RecurringOptionsSelect
                                            selectedDate={dueDate?.toDate()}
                                        />
                                    </FormItem>
                                </Col>
                                <Col md={12} style={{ display: "flex" }}>
                                    {recurringOption === RecurringOptions.CUSTOM && (
                                        <Popover
                                            content={renderCustomPreview(
                                                customRule,
                                                intl,
                                                t,
                                            )}
                                            title="Forhåndsvisning">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignSelf: "center",
                                                    justifyContent: "flex-end",
                                                    fontSize: 14,
                                                    // textAlign: "right",
                                                }}>
                                                <InfoCircleOutlined />
                                                <div style={{ marginLeft: 4 }}>
                                                    Hvilke datoer oprettes?
                                                </div>
                                            </div>
                                        </Popover>
                                    )}
                                    {recurringOption &&
                                        recurringOption !== RecurringOptions.NEVER &&
                                        recurringOption !== RecurringOptions.CUSTOM && (
                                            <Popover
                                                content={renderPreview(
                                                    recurringOption,
                                                    dueDate,
                                                    intl,
                                                    t,
                                                )}
                                                title="Forhåndsvisning">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignSelf: "center",
                                                        justifyContent: "flex-end",
                                                        fontSize: 14,
                                                        // textAlign: "right",
                                                    }}>
                                                    <InfoCircleOutlined />
                                                    <div style={{ marginLeft: 4 }}>
                                                        Hvilke datoer oprettes?
                                                    </div>
                                                </div>
                                            </Popover>
                                        )}
                                </Col>
                                <Col md={24}>
                                    {recurringOption === RecurringOptions.CUSTOM && (
                                        <div
                                            style={{
                                                padding: 16,
                                                background: StyleGuide.palette.grey100,
                                                borderRadius: 4,
                                            }}>
                                            <RepeatOptionsForm
                                                interval={allValues?.interval}
                                                frequency={allValues?.frequency}
                                                startDate={dueDate}
                                                timeOption={timeOption}
                                            />
                                            {/* {customRule.toText()} */}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            {/* <Row style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}></Col>
                            </Row> */}
                            <Row style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}>{t("audit.schedule.responsible")}</Col>
                                <Col md={24}>
                                    <FormItem
                                        // label={t("audit.schedule.responsible")}
                                        name="responsibleId"
                                        valuePropName="value"
                                        style={{ width: "100%" }}>
                                        <FormResponsibleSelect />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}>
                                    <Checkbox
                                        checked={isMultiDay}
                                        onChange={e => setisMultiDay(e.target.checked)}>
                                        {t("audit.schedule.multidayaudit")}
                                    </Checkbox>
                                </Col>
                            </Row>
                            {isMultiDay && (
                                <Row style={{ width: "100%", marginBottom: 16 }}>
                                    <Col
                                        md={5}
                                        style={{ display: "flex", alignItems: "center" }}>
                                        {t("audit.schedule.startDate")}
                                    </Col>
                                    <Col md={19}>
                                        <FormItem
                                            name="startDateBeforeAuditDueDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please select a start date!",
                                                },
                                            ]}
                                            initialValue={7}>
                                            <Select
                                                style={{ minWidth: 200 }}
                                                placeholder="Vælg antal dage før deadline"
                                                allowClear
                                                options={range(1, 31).map(day => ({
                                                    value: day,
                                                    label: `${day} ${t(
                                                        "audit.schedule.multidaybeforedeadline",
                                                    )}`,
                                                }))}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            )}
                            <Row style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}>
                                    <Tooltip
                                        title={t(
                                            "audit.templates.addtionalSettingsInfo",
                                        )}>
                                        <Checkbox
                                            checked={getNotificationAfter}
                                            onChange={e =>
                                                setGetNotificationAfter(e.target.checked)
                                            }>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}>
                                                {t(
                                                    "audit.schedule.notifyafterdeadlineLabel",
                                                )}
                                                <InfoCircleOutlined
                                                    style={{ marginLeft: 4 }}
                                                />
                                            </div>
                                        </Checkbox>
                                    </Tooltip>
                                </Col>
                            </Row>
                            {getNotificationAfter && (
                                <Row style={{ width: "100%", marginBottom: 16 }}>
                                    <Col
                                        md={5}
                                        style={{ display: "flex", alignItems: "center" }}>
                                        {t("audit.schedule.notifyafter")}
                                    </Col>
                                    <Col md={19}>
                                        <FormItem
                                            name="notifyDaysAfterDueDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please select a start date!",
                                                },
                                            ]}
                                            initialValue={7}>
                                            <Select
                                                // defaultValue="lucy"
                                                style={{ width: "100%", minWidth: 200 }}
                                                placeholder={t(
                                                    "audit.templates.addtionalSettingsNotifyDropdownPlaceholder",
                                                )}
                                                // onChange={onSelectBackup}
                                                allowClear
                                                options={range(1, 31).map(day => ({
                                                    value: day,
                                                    label: `${day} ${t(
                                                        "audit.schedule.notifyafterdeadline",
                                                    )}`,
                                                }))}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            )}

                            <FormItem style={{ width: "100%" }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    style={{ width: "100%" }}>
                                    {t("audit.schedule.submit")}
                                </Button>
                            </FormItem>
                        </Form>
                    </Col>
                    <AlreadyScheduled templateId={templateId} />
                </Row>
            )}
        </Modal>
    );
}

export function renderPreview(
    recurringOption: RecurringOptions,
    dueDate: Dayjs,
    intl: Intl,
    t: (key: string) => string,
) {
    const userLocale = getLocale(intl);

    const allDates = generateRecurringAudits(recurringOption, dueDate.toString());
    const dates = allDates.slice(0, 5); // Show only 5 dates at most, to avoid too much

    const weekNumber = (d: Date) => (
        <span style={{ color: StyleGuide.palette.grey500 }}>{` - (${t(
            "general.week",
        )} ${format(d, "ww")})`}</span>
    );

    const lastDate =
        allDates.length > 5 ? (
            <div>
                <div>...</div>
                <div>
                    {format(allDates[allDates.length - 1], "EEEE, dd MMM yyyy", {
                        locale: userLocale,
                    })}
                    {weekNumber(allDates[allDates.length - 1])}
                </div>
            </div>
        ) : null;

    return (
        <div>
            {dates.map(d => (
                <p>
                    {format(d, "EEEE, dd MMM yyyy", {
                        locale: userLocale,
                    })}
                    {weekNumber(d)}
                </p>
            ))}
            {lastDate}
        </div>
    );
}
export function renderCustomPreview(rule: RRule, intl: Intl, t: (key: string) => string) {
    const userLocale = getLocale(intl);

    const updatedRule = updateCountBasedOnFrequency(rule.toString());
    const allDates = updatedRule.all();

    const dates = allDates.slice(0, 5); // Show only 5 dates at most, to avoid too much

    const weekNumber = (d: Date) => (
        <span style={{ color: StyleGuide.palette.grey500 }}>{` - (${t(
            "general.week",
        )} ${format(d, "ww")})`}</span>
    );

    const lastDate =
        allDates.length > 5 ? (
            <div>
                <div>...</div>
                {/* <div>
                    {format(allDates[allDates.length - 1], "EEEE, dd MMM yyyy", {
                        locale: userLocale,
                    })}
                    {weekNumber(allDates[allDates.length - 1])}
                </div> */}
            </div>
        ) : null;

    return (
        <div>
            {dates.map(d => (
                <p>
                    {format(d, "EEEE, dd MMM yyyy", {
                        locale: userLocale,
                    })}
                    {weekNumber(d)}
                </p>
            ))}
            {lastDate}
        </div>
    );
}

function updateCountBasedOnFrequency(oldRule: string): RRule {
    const originalRRule = RRule.fromString(oldRule);
    const { freq } = originalRRule.origOptions; // RRule.DAILY, WEEKLY, etc.

    let newCount = 1;
    switch (freq) {
        case RRule.DAILY:
            newCount = 6; // example
            break;
        case RRule.WEEKLY:
            newCount = 6; // example
            break;
        case RRule.MONTHLY:
            newCount = 6; // example
            break;
        default:
            newCount = 6; // example fallback
            break;
    }

    const newOptions = {
        ...originalRRule.origOptions,
        count: newCount,
    };

    const updatedRule = new RRule(newOptions);
    return updatedRule;
}
