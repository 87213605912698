import React from "react";
import { ResponsiveHeatMap, CellComponent, HeatMap } from "@nivo/heatmap";
import { transformAuditDataForHeatmap } from "./utils";
import { differenceInDays } from "date-fns";
import { StyleGuide } from "styles/StyleGuide";

const CustomAuditCell = (props: any) => {
    console.log({ props });

    return (
        <g
            transform={`translate(${props.cell.x - props.cell.width / 2}, ${
                (props.cell.y?.todo || props.cell.y) - props.cell.height / 2
            })`}>
            <rect
                width={props.cell.width}
                height={props.cell.height}
                fill={props.cell.color}
                rx="15"
                strokeWidth={0}
                stroke="#555555"
            />
        </g>
    );
};
const transformDataForHeatmap = (data: any[] = []) => {
    const now = Math.floor(Date.now() / 1000); // Current time in seconds (UNIX timestamp)
    let audits: any[] = [];
    data.forEach(item => {
        if (
            item.needsResolution === "true" &&
            item.isCompleted === "true" &&
            item.dueDate
        ) {
            // Difference between completeTime and dueDate
            const days = differenceInDays(
                new Date(),
                new Date(Number(item.completeTime) * 1000),
                // new Date(Number(item.dueDate) * 1000),
            );
            audits.push({
                id: "Dage uden handling", // Use auditId as id
                data: [
                    {
                        x: item.templateTitle + " - " + item.subGroup, // X-axis value
                        y: days, // Y-axis value based on conditions
                    },
                ],
            });
        }
    });
    return audits;
};

const data2 = [
    {
        id: "Chris",
        data: [
            {
                x: "Mandag 1/10",
                y: 1,
            },
            {
                x: "Tirsdag 2/10",
                y: 0,
            },
            {
                x: "Onsdag 3/10",
                y: 0,
            },
            {
                x: "Torsdag 4/10",
                y: 1,
            },
            {
                x: "Fredag 5/10",
                y: 1,
            },
            {
                x: "Lørdag 6/10",
                y: 0,
            },
            {
                x: "Søndag 7/10",
                y: 1,
            },
            {
                x: "Mandag 8/10",
                y: 1,
            },
            {
                x: "Tirsdag 9/10",
                y: 0,
            },
            {
                x: "Onsdag 10/10",
                y: 0,
            },
            {
                x: "Torsdag 11/10",
                y: 1,
            },
            {
                x: "Fredag 12/10",
                y: 1,
            },
            {
                x: "Lørdag 13/10",
                y: 0,
            },
            {
                x: "Søndag 14/10",
                y: 1,
            },
            {
                x: "Mandag 15/10",
                y: 1,
            },
            // {
            //     x: "Tirsdag 16/10",
            //     y: 0,
            // },
            // {
            //     x: "Onsdag 17/10",
            //     y: 0,
            // },
            // {
            //     x: "Torsdag 18/10",
            //     y: 1,
            // },
            // {
            //     x: "Fredag 19/10",
            //     y: 1,
            // },
            // {
            //     x: "Lørdag 20/10",
            //     y: 0,
            // },
            // {
            //     x: "Søndag 21/10",
            //     y: 1,
            // },
            // {
            //     x: "Mandag 22/10",
            //     y: 1,
            // },
            // {
            //     x: "Tirsdag 23/10",
            //     y: 0,
            // },
            // {
            //     x: "Onsdag 24/10",
            //     y: 0,
            // },
            // {
            //     x: "Torsdag 25/10",
            //     y: 1,
            // },
            // {
            //     x: "Fredag 26/10",
            //     y: 1,
            // },
            // {
            //     x: "Lørdag 27/10",
            //     y: 0,
            // },
            // {
            //     x: "Søndag 28/10",
            //     y: 1,
            // },
            // {
            //     x: "Mandag 29/10",
            //     y: 1,
            // },
            // {
            //     x: "Tirsdag 30/10",
            //     y: 0,
            // },
            // {
            //     x: "Onsdag 31/10",
            //     y: 0,
            // },
        ],
    },
    {
        id: "Leif",
        data: [
            {
                x: "Mandag 1/10",
                y: 0,
            },
            {
                x: "Tirsdag 2/10",
                y: 1,
            },
            {
                x: "Onsdag 3/10",
                y: 0,
            },
            {
                x: "Torsdag 4/10",
                y: 1,
            },
            {
                x: "Fredag 5/10",
                y: 0,
            },
            {
                x: "Lørdag 6/10",
                y: 1,
            },
            {
                x: "Søndag 7/10",
                y: 0,
            },
            {
                x: "Mandag 8/10",
                y: 0,
            },
            {
                x: "Tirsdag 9/10",
                y: 1,
            },
            {
                x: "Onsdag 10/10",
                y: 0,
            },
            {
                x: "Torsdag 11/10",
                y: 1,
            },
            {
                x: "Fredag 12/10",
                y: 0,
            },
            {
                x: "Lørdag 13/10",
                y: 1,
            },
            {
                x: "Søndag 14/10",
                y: 0,
            },
            {
                x: "Mandag 15/10",
                y: 0,
            },
            // {
            //     x: "Tirsdag 16/10",
            //     y: 1,
            // },
            // {
            //     x: "Onsdag 17/10",
            //     y: 0,
            // },
            // {
            //     x: "Torsdag 18/10",
            //     y: 1,
            // },
            // {
            //     x: "Fredag 19/10",
            //     y: 0,
            // },
            // {
            //     x: "Lørdag 20/10",
            //     y: 1,
            // },
            // {
            //     x: "Søndag 21/10",
            //     y:
        ],
    },
];

const HeatmapWithCustomCellsNeedsResolution = ({ audits }: { audits: any[] }) => {
    const data = transformDataForHeatmap(audits);

    console.log({ data });

    return (
        <ResponsiveHeatMap
            // @ts-ignore
            data={data}
            // sizeVariation={{
            //     sizes: [0.0, 0.5],
            // }}
            colors={{
                scheme: "oranges",
                type: "sequential",
                // divergeAt: 0.5,
                colors: [
                    StyleGuide.palette.statusHardWarningLight,
                    StyleGuide.palette.statusHardWarningDark,
                ],
                minValue: 0,
                // maxValue: 5,
            }}
            margin={{ top: 0, right: 40, bottom: 100, left: 120 }}
            // valueFormat=">-.2s"
            // enableGridX
            // enableGridY
            tooltip={({ cell }) => (
                <div
                    key={"tooltip"}
                    style={{
                        padding: 16,
                        backgroundColor: "#fff",
                        borderRadius: 8,
                        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
                    }}>
                    <p>{cell.data.x}</p>
                    <strong>{cell.value} dage uden handling</strong>
                </div>
            )}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -25,
                legend: "",
                legendOffset: 46,
                truncateTickAt: 24,
            }}
            // axisRight={{
            //     tickSize: 0,
            //     tickPadding: 5,
            //     tickRotation: 0,
            //     // legend: "country",
            //     // legendPosition: "middle",
            //     legendOffset: 70,
            //     truncateTickAt: 0,
            // }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: "country",
                // legendPosition: "middle",
                legendOffset: -72,
                truncateTickAt: 0,
            }}
            // colors={{
            //     type: "diverging",
            //     scheme: "red_yellow_blue",
            //     divergeAt: 0.5,
            //     minValue: -100000,
            //     maxValue: 100000,
            // }}

            // cellComponent={CustomAuditCell} //circle"
            emptyColor="#555555"
            // legends={[
            //     {
            //         anchor: "bottom",
            //         translateX: 0,
            //         translateY: 30,
            //         length: 400,
            //         thickness: 8,
            //         direction: "row",
            //         tickPosition: "after",
            //         tickSize: 3,
            //         tickSpacing: 4,
            //         tickOverlap: false,
            //         tickFormat: ">-.2s",
            //         title: "Value →",
            //         titleAlign: "start",
            //         titleOffset: 4,
            //     },
            // ]}
        />
    );
};

export default HeatmapWithCustomCellsNeedsResolution;
