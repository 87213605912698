import { Select } from "antd";
import { getDateAndMonth, getDateOfMonth, getDayOfWeek } from "general/dates";
import { RecurringOptions } from "general/enums";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks";

export default function RecurringOptionsSelect({
    selectedDate,
    ...rest
}: {
    selectedDate: Date;
}) {
    const { t } = useTranslation();
    const intl = useAppSelector(state => state.user.userDoc.intl);
    return (
        <Select
            {...rest}
            placeholder="select schedule"
            options={[
                {
                    label: t("audit.schedule.repeatnever"),
                    value: RecurringOptions.NEVER,

                    // {
                    //     label: "Custom",
                    //     value: RecurringOptions.CUSTOM,
                    // },
                },
                {
                    label: "Daglig",
                    options: [
                        {
                            label: t("audit.schedule.repeatdaily"),
                            value: RecurringOptions.DAILY,
                        },
                        {
                            label: t("audit.schedule.repeatweekdaily"),
                            value: RecurringOptions.WEEK_DAILY,
                        },
                    ],
                },
                {
                    label: "Ugentlig",
                    options: [
                        {
                            label: `${t("audit.schedule.repeatweekly")} ${getDayOfWeek(
                                selectedDate,
                                intl,
                            )}`,
                            value: RecurringOptions.WEEKLY,
                        },
                        {
                            label: `${t("audit.schedule.repeatnbiweekly")} ${getDayOfWeek(
                                selectedDate,
                                intl,
                            )}`,
                            value: RecurringOptions.BI_WEEKLY,
                        },
                        {
                            label: `${t(
                                "audit.schedule.repeatntriweekly",
                            )} ${getDayOfWeek(selectedDate, intl)}`,
                            value: RecurringOptions.TRI_WEEKLY,
                        },
                    ],
                },
                {
                    label: "Månedlig",
                    options: [
                        {
                            label: `Den ${getDateOfMonth(selectedDate, intl)} ${t(
                                "audit.schedule.repeatmonthly",
                            )}`,
                            value: RecurringOptions.DATE_MONTHLY,
                        },
                        {
                            label: `${getDayOfWeek(selectedDate, intl)} ${t(
                                "audit.schedule.repeatmonthly",
                            )}`,
                            value: RecurringOptions.MONTHLY,
                        },
                        {
                            label: `${t("Primo")} ${t("audit.schedule.repeatmonthly")}`,
                            value: RecurringOptions.PRIMO_MONTH,
                        },
                        {
                            label: `${t("Ultimo")} ${t("audit.schedule.repeatmonthly")}`,
                            value: RecurringOptions.ULTIMO_MONTH,
                        },
                    ],
                },
                {
                    label: "Hver 2. måned",
                    options: [
                        {
                            label: `Den ${getDateOfMonth(selectedDate, intl)} ${t(
                                "audit.schedule.repeatbimonthly",
                            )}`,
                            value: RecurringOptions.DATE_BI_MONTHLY,
                        },
                        {
                            label: `${getDayOfWeek(selectedDate, intl)} ${t(
                                "audit.schedule.repeatbimonthly",
                            )}`,
                            value: RecurringOptions.BI_MONTHLY,
                        },
                        {
                            label: `${t("Primo")} ${t("audit.schedule.repeatbimonthly")}`,
                            value: RecurringOptions.PRIMO_BI_MONTH,
                        },
                        {
                            label: `${t("Ultimo")} ${t(
                                "audit.schedule.repeatbimonthly",
                            )}`,
                            value: RecurringOptions.ULTIMO_BI_MONTH,
                        },
                    ],
                },

                {
                    label: "Kvartalsvis",
                    options: [
                        {
                            label: `Den ${getDateOfMonth(selectedDate, intl)} ${t(
                                "audit.schedule.repeatquarterly",
                            )}`,
                            value: RecurringOptions.DATE_QUARTERLY,
                        },
                        {
                            label: `${getDayOfWeek(selectedDate, intl)} ${t(
                                "audit.schedule.repeatquarterly",
                            )}`,
                            value: RecurringOptions.QUARTERLY,
                        },
                        {
                            label: `${t("Primo")} ${t("audit.schedule.repeatquarterly")}`,
                            value: RecurringOptions.PRIMO_QUARTERLY,
                        },
                        {
                            label: `${t("Ultimo")} ${t(
                                "audit.schedule.repeatquarterly",
                            )}`,
                            value: RecurringOptions.ULTIMO_QUARTERLY,
                        },
                    ],
                },
                {
                    label: "Hver 4. måned",
                    options: [
                        {
                            label: `Den ${getDateOfMonth(selectedDate, intl)} ${t(
                                "audit.schedule.repeatfourmonths",
                            )}`,
                            value: RecurringOptions.DATE_EVERY_FOURTH_MONTHLY,
                        },
                        {
                            label: `${getDayOfWeek(selectedDate, intl)} ${t(
                                "audit.schedule.repeatfourmonths",
                            )}`,
                            value: RecurringOptions.EVERY_FOURTH_MONTHLY,
                        },
                        {
                            label: `${t("Primo")} ${t(
                                "audit.schedule.repeatfourmonths",
                            )}`,
                            value: RecurringOptions.PRIMO_EVERY_FOURTH_MONTH,
                        },
                        {
                            label: `${t("Ultimo")} ${t(
                                "audit.schedule.repeatfourmonths",
                            )}`,
                            value: RecurringOptions.ULTIMO_EVERY_FOURTH_MONTH,
                        },
                    ],
                },
                {
                    label: "Halvårlig",
                    options: [
                        {
                            label: `Den ${getDateOfMonth(selectedDate, intl)} ${t(
                                "audit.schedule.repeatsemiyearly",
                            )}`,
                            value: RecurringOptions.SEMI_YEARLY,
                        },
                    ],
                },
                {
                    label: "Årlig",
                    options: [
                        {
                            label: `${getDateAndMonth(selectedDate, intl)} ${t(
                                "audit.schedule.repeatyearly",
                            )}`,
                            value: RecurringOptions.YEARLY,
                        },
                        {
                            label: `${getDateAndMonth(selectedDate, intl)} ${t(
                                "audit.schedule.repeatbiyearly",
                            )}`,
                            value: RecurringOptions.BI_YEARLY,
                        },
                    ],
                },
                {
                    label: "Avanceret",
                    options: [
                        {
                            label: t("audit.schedule.repeatcustom"),
                            value: RecurringOptions.CUSTOM,
                        },
                    ],
                },
            ]}
        />
    );
}
