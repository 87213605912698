import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { TagStatus } from "../Views/Billing/Table";
import { useAppSelector } from "../hooks";
import { isAdmin } from "components/AdminOnly";
import { firestore } from "../firebase";
import {
    AirCleaner,
    OfferStatus,
    PriceType,
} from "Views/Agreements/Context/offerReducer";

export interface AppOffer {
    id: string;
    airCleaner: AirCleaner | null;
    createdBy: {
        uid: string;
        displayName: string;
        email: string;
    };
    refId: string; // Generate on client
    finalPrice: number;
    offer: {
        m2: number;
        sensors: number;
        gps: number;
        tablets: number;
        dustMeasurements: number;
        priceType: PriceType;
        discount: number | null;
        sweepingGranules: number;
        discountGranules: number | null;
        note: string | null;
        internalNote: string | null;
        finalPrice?: number | null;
        finalLiter?: number | null;
        deliveryOption?: number;
    };
    acceptTime?: Date;
    declineTime?: Date;
    orgId?: string;
    isDeleted: boolean;
    title: string;
    contact: {
        att: string;
    };
    status: OfferStatus;
    createTime: Date;
    updateTime: Date;
}
export default function useAppOffers() {
    const [offers, setOffers] = useState<AppOffer[]>([]);
    const currentUserId = useAppSelector(state => state.user.details.uid);
    const admin = isAdmin(currentUserId);
    useEffect(() => {
        let unsubscribe: any = null;
        let query = firestore.collection(
            `appOffers`,
        ) as firebase.firestore.Query<firebase.firestore.DocumentData>;
        if (!admin) {
            query = query.where("createdBy.uid", "==", currentUserId);
        }
        console.log({ currentUserId, query });

        unsubscribe = query
            .where("isDeleted", "==", false)
            .orderBy("updateTime", "desc")
            .onSnapshot(querySnapshot => {
                if (!querySnapshot.empty) {
                    const list: AppOffer[] = querySnapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            ...data,
                            createTime: data.createTime.toDate(),
                            updateTime: data.updateTime.toDate(),
                            id: doc.id,
                            refId: getRefId(
                                data.createdBy.displayName,
                                data.createTime.toDate().getTime(),
                            ),
                        } as AppOffer;
                    });
                    setOffers(list);
                } else {
                    setOffers([]);
                }
            }, console.error);
        return unsubscribe;
    }, []);
    return {
        offers,
    };
}

function getRefId(name: string, time: number) {
    const user = getFirstThreeInitials(name);
    return `${user}-${time}`;
}

function getFirstThreeInitials(name: string) {
    const [a, b, c] = name.split("");
    return `${a || ""}${b || ""}${c || ""}`.toUpperCase();
}
