import React from "react";
import { ResponsiveHeatMap, CellComponent, HeatMap } from "@nivo/heatmap";
import { transformAuditDataForHeatmap } from "./utils";
import { differenceInDays } from "date-fns";
import { StyleGuide } from "styles/StyleGuide";

const CustomAuditCell = (props: any) => {
    console.log({ props });

    return (
        <g
            transform={`translate(${props.cell.x - props.cell.width / 2}, ${
                (props.cell.y?.todo || props.cell.y) - props.cell.height / 2
            })`}>
            <rect
                width={props.cell.width}
                height={props.cell.height}
                fill={props.cell.color}
                rx="15"
                strokeWidth={0}
                stroke="#555555"
            />
        </g>
    );
};
const transformDataForHeatmap = (data: any[] = []) => {
    let audits: any[] = [];
    data.forEach(item => {
        if (item.hasOnHoldTasks === "true" && item.tasks) {
            const holdTime =
                item.tasks &&
                Object.values(item.tasks).find((task: any) => task.onHoldTime);
            if (!holdTime.onHoldTime) return;

            console.log({ onHoldTime: holdTime.onHoldTime });

            // Difference between onHoldTime and now
            const days = differenceInDays(
                new Date(),
                new Date(Number(holdTime.onHoldTime._seconds) * 1000),
            );
            audits.push({
                id: "Dage på hold", // Use auditId as id
                data: [
                    {
                        x: item.templateTitle + " - " + item.subGroup, // X-axis value
                        y: days, // Y-axis value based on conditions
                    },
                ],
            });
        }
    });
    return audits;
};

const HeatmapWithCustomCellsOnHold = ({ audits }: { audits: any[] }) => {
    const data = transformDataForHeatmap(audits);

    console.log({ data });

    return (
        <ResponsiveHeatMap
            // @ts-ignore
            data={data}
            // sizeVariation={{
            //     sizes: [0.0, 0.5],
            // }}
            colors={{
                scheme: "oranges",
                type: "sequential",
                // divergeAt: 0.5,
                colors: [
                    StyleGuide.palette.statusWarningLight,
                    StyleGuide.palette.statusWarningDark,
                ],
                minValue: 0,
                // maxValue: 5,
            }}
            margin={{ top: 100, right: 40, bottom: 0, left: 120 }}
            // valueFormat=">-.2s"
            // enableGridXs
            // enableGridY
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 25,
                legend: "",
                legendOffset: 46,
                truncateTickAt: 24,
            }}
            // axisRight={{
            //     tickSize: 0,
            //     tickPadding: 5,
            //     tickRotation: 0,
            //     // legend: "country",
            //     // legendPosition: "middle",
            //     legendOffset: 70,
            //     truncateTickAt: 0,
            // }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: "country",
                // legendPosition: "middle",
                legendOffset: -72,
                truncateTickAt: 0,
            }}
            // colors={{
            //     type: "diverging",
            //     scheme: "red_yellow_blue",
            //     divergeAt: 0.5,
            //     minValue: -100000,
            //     maxValue: 100000,
            // }}

            tooltip={({ cell }) => (
                <div
                    key={"tooltip"}
                    style={{
                        padding: 16,
                        backgroundColor: "#fff",
                        borderRadius: 8,
                        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
                    }}>
                    <p>{cell.data.x}</p>
                    <strong>{cell.value} dage på hold</strong>
                </div>
            )}
            // cellComponent={CustomAuditCell} //circle"
            emptyColor="#555555"
            // legends={[
            //     {
            //         anchor: "bottom",
            //         translateX: 0,
            //         translateY: 30,
            //         length: 400,
            //         thickness: 8,
            //         direction: "row",
            //         tickPosition: "after",
            //         tickSize: 3,
            //         tickSpacing: 4,
            //         tickOverlap: false,
            //         tickFormat: ">-.2s",
            //         title: "Value →",
            //         titleAlign: "start",
            //         titleOffset: 4,
            //     },
            // ]}
        />
    );
};

export default HeatmapWithCustomCellsOnHold;
