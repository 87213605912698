import { useState } from "react";
import { Layout, Row, Col, Button, Badge, Spin, Space } from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import { useTranslation } from "react-i18next";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import ScheduleModal from "./ScheduleModal";
import { AuditTemplate } from "../../modules/auditTemplates/types";
import EditScheduleModal from "./EditScheduleModal";
import { Dayjs } from "dayjs";
import { isAfter, isBefore } from "date-fns";
import { AuditSchedule as AuditScheduleType } from "modules/auditSchedule/types";
import { CalendarMode } from "antd/lib/calendar/generateCalendar";
import { useDispatch } from "react-redux";
import AuditsExpire from "./AuditsExpire";
import AdminOnly from "components/AdminOnly";
import { Colors } from "Layout/Colors";
import { getLocaleAntd } from "general/intl";
import { useAppSelector } from "hooks";
import AuditCalendar from "./Calendar/AuditCalendar";

const { Content } = Layout;
interface IProps {
    orgId: string;
    auditSchedules: AuditScheduleType[];
    loading: boolean;
    auditTemplatesByKey: { [id: string]: AuditTemplate };
    // initialDate: Date;
    addAuditSchedule: ({
        taskTitle, // QUICK TASK
        templateId,
        recurringOption,
        dueDate,
        startDaysBeforeDueDate,
        responsible,
        auditSourceType,
        repeatRule,
    }: // auditTemplateCategory,
    {
        taskTitle?: string; // QUICK TASK
        templateId: string;
        recurringOption: AuditScheduleType["recurringOption"];
        dueDate: Date;
        startDaysBeforeDueDate?: AuditScheduleType["startDaysBeforeDueDate"];
        responsible: AuditScheduleType["responsible"];
        auditSourceType?: string;
        repeatRule?: string;
        // auditTemplateCategory?: string | null;
    }) => void;
}

function AuditSchedule(props: IProps) {
    const intl = useAppSelector(state => state.user.userDoc.intl);
    const dispatch = useDispatch();
    // const [value, setValue] = useState(dayjs(props.initialDate));
    const { t } = useTranslation();
    const [editModal, setEditModal] = useState<string | null>(null);
    const [selectedValue, setSelectedValue] = useState<Date | undefined>(undefined);
    const onPanelChange = (value: Dayjs, _: CalendarMode) => {
        console.log({ onPanelChange: value });
        // setValue(value);
        // dispatch(setAuditCalendar({ date: value.toDate(), mode }));
    };

    const locale = getLocaleAntd(intl);

    const onSelect = (_: Dayjs) => {
        // setValue(value);
        // setSelectedValue(value);
        // setEditModal(value);
        // alert("SELECT" + value.toString());
    };

    const openModal = () => {};
    const closeModal = () => {
        setSelectedValue(undefined);
    };
    const closeEditModal = () => {
        setEditModal(null);
    };

    // Only show error after a field is touched.
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.AUDITS}>
                    <Content style={{ margin: "24px 24px 0" }}>
                        <Row gutter={16}>
                            <AdminOnly>
                                <Col md={24} xs={24} style={{ paddingBottom: 20 }}>
                                    <AuditsExpire />
                                </Col>
                            </AdminOnly>
                            <Col md={24} xs={24}>
                                <Row
                                    justify="start"
                                    style={{ alignItems: "center", paddingBottom: 20 }}>
                                    {/* style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: 8,
                                    }}> */}
                                    <Col md={18} xs={24}>
                                        <Badge
                                            status="error"
                                            text={t("audit.schedule.legend.error")}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                lineHeight: 0,
                                                whiteSpace: "nowrap",
                                                marginRight: 8,
                                            }}
                                        />
                                        <Badge
                                            color="cyan"
                                            text={t("audit.schedule.legend.inprogress")}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                lineHeight: 0,
                                                whiteSpace: "nowrap",
                                                marginRight: 8,
                                            }}
                                        />
                                        <Badge
                                            color={Colors.warning}
                                            text={t("audit.schedule.legend.warning")}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                lineHeight: 0,
                                                whiteSpace: "nowrap",
                                                marginRight: 8,
                                            }}
                                        />
                                        <Badge
                                            color={Colors.hardWarning}
                                            text={t("audit.schedule.legend.hardWarning")}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                lineHeight: 0,
                                                whiteSpace: "nowrap",
                                                marginRight: 8,
                                            }}
                                        />
                                        <Badge
                                            status="processing"
                                            text={t("audit.schedule.legend.processing")}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                lineHeight: 0,
                                                whiteSpace: "nowrap",
                                                marginRight: 8,
                                            }}
                                        />
                                        <Badge
                                            status="success"
                                            text={t("audit.schedule.legend.success")}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                lineHeight: 0,
                                                whiteSpace: "nowrap",
                                                marginRight: 8,
                                            }}
                                        />
                                        <Badge
                                            color={Colors.voided}
                                            text={t("audit.schedule.legend.voided")}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                lineHeight: 0,
                                                whiteSpace: "nowrap",
                                            }}
                                        />
                                    </Col>
                                    <Col md={6} xs={24}>
                                        <div style={{ textAlign: "right" }}>
                                            <Button
                                                type="primary"
                                                size="large"
                                                onClick={() =>
                                                    setSelectedValue(new Date())
                                                }>
                                                Tilføj opgave
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Spin spinning={props.loading}>
                                    {/* <Calendar
                                        className="audit-calendar"
                                        value={value}
                                        // dateCellRender={dateCellRender}
                                        onSelect={onSelect}
                                        cellRender={cellRender}
                                        onPanelChange={onPanelChange}
                                        // monthCellRender={monthCellRender}
                                        locale={locale}
                                    /> */}
                                    <AuditCalendar
                                        auditSchedules={props.auditSchedules}
                                        addAuditSchedule={setSelectedValue}
                                        editAuditSchedule={setEditModal}
                                    />
                                </Spin>
                            </Col>
                        </Row>
                        <EditScheduleModal id={editModal} handleClose={closeEditModal} />
                        <ScheduleModal
                            handleClose={closeModal}
                            selectedValue={selectedValue}
                            addAuditSchedule={props.addAuditSchedule}
                        />
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
            {/* <Sider
                style={{ borderLeft: "1px solid #f0f0f0" }}
                trigger={<CaretLeftOutlined />}
                zeroWidthTriggerStyle={{ border: "1px solid #f0f0f0" }}
                collapsedWidth={0}
                defaultCollapsed
                reverseArrow
                collapsible>
                {props.auditTemplatesByKey && (
                    <ul>
                        {Object.keys(props.auditTemplatesByKey).map((key: string) => {
                            const template = props.auditTemplatesByKey[key];
                            return (
                                <li key={key}>
                                    <p>{template.title}</p>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </Sider> */}
        </>
    );
}

export default AuditSchedule;

export function getTaskStatus({
    item,
    isDelayed,
    startDate,
}: {
    item: any;
    isDelayed: boolean;
    startDate: Date;
}) {
    if (item.isCompleted && item.hasOnHoldTasks) {
        return "warning";
    }
    if (item.isCompleted && item.needsResolution) {
        return "hardWarning";
    }
    if (item.isCompleted) {
        return "success";
    }
    if (isDelayed) {
        return "error";
    }

    if (isAfter(new Date(), startDate) && isBefore(new Date(), item.dueDate.toDate())) {
        return "inprogress";
    }
    return "processing";
}
