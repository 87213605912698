import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function IssueDescription({
    id,
    description,
    updateDescription,
}: {
    id: string;
    description: any;
    updateDescription: (data: any) => void;
    // updateNote: (noteId: string, text: string) => void;
}) {
    console.log({ id });

    const { t } = useTranslation();
    const [tmpDescription, setTmpDescription] = useState<string | null>(null);

    return (
        <>
            {tmpDescription !== null ? (
                <div style={{ position: "relative" }}>
                    <TextArea
                        autoFocus
                        value={tmpDescription}
                        autoSize={{ minRows: 2 }}
                        onChange={e => setTmpDescription(e.target.value)}
                        placeholder={t("issues.edit.comment")}
                    />
                    <div
                        style={{
                            display: "flex",
                            position: "absolute",
                            right: -8,
                            top: -42,
                        }}>
                        <Button
                            type="default"
                            // shape="circle"
                            // icon={<EditOutlined />}
                            onClick={() => setTmpDescription(null)}>
                            {t("general.cancel")}
                        </Button>
                        <div style={{ marginRight: 8 }} />
                        <Button
                            type="primary"
                            // shape="circle"
                            // icon={<EditOutlined />}
                            onClick={() => {
                                updateDescription({ description: tmpDescription });
                                setTmpDescription(null);
                            }}>
                            {t("general.save")}
                        </Button>
                    </div>
                </div>
            ) : (
                <>
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}>
                        <div>
                            <span style={{ fontSize: 18 }}>{description}</span>
                        </div>
                        <div style={{ position: "absolute", right: -8, top: -42 }}>
                            <Tooltip title="Ret opgave beskrivelse">
                                <Button
                                    type="default"
                                    // shape="circle"
                                    icon={<EditOutlined />}
                                    onClick={() => setTmpDescription(description)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    {/* <Meta
                            description={
                                <span style={{ fontSize: 12 }}>
                                    {t("date.exactFormat", {
                                        relativeTime: note.createTime,
                                        dayOfWeek: note.createTime,
                                        dayOfMonth: note.createTime,
                                        month: note.createTime,
                                        time: note.createTime,
                                    })}{" "}
                                    {note?.updateTime && (
                                        <Tooltip
                                            title={`Seneste opdateret: ${t(
                                                "date.exactFormat",
                                                {
                                                    relativeTime: note.updateTime,
                                                    dayOfWeek: note.updateTime,
                                                    dayOfMonth: note.updateTime,
                                                    month: note.updateTime,
                                                    time: note.updateTime,
                                                },
                                            )}`}>
                                            (opdateret)
                                        </Tooltip>
                                    )}
                                </span>
                            }
                        /> */}
                </>
            )}
        </>
    );
}
