import React, { PureComponent } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { StyleGuide } from "styles/StyleGuide";
import { Tooltip as AntTooltip } from "antd";
import { result, mapData } from "./bubbleSample";

const data = [
    {
        name: "Alarmer",
        incomplete: 1,
        completed: 4,
    },
    {
        name: "Belægningkontrol",
        incomplete: 5,
        completed: 22,
    },
    {
        name: "Beredskabplan",
        incomplete: 1,
        completed: 7,
    },
    {
        name: "Betonelementer",
        incomplete: 1,
        completed: 2,
    },
    {
        name: "Brandudstyr",
        incomplete: 1,
        completed: 8,
    },
    {
        name: "Brønde, drænbrønde og sandfang",
        incomplete: 2,
        completed: 16,
    },
    {
        name: "Dieselforbrug",
        incomplete: 2,
        completed: 46,
    },
    {
        name: "Elforbrug",
        incomplete: 1,
        completed: 23,
    },
    {
        name: "Elværktøj & kabler",
        incomplete: 2,
        completed: 3,
    },
    {
        name: "Førstehjælpsudstyr",
        incomplete: 1,
        completed: 8,
    },
    {
        name: "Haller",
        incomplete: 1,
        completed: 5,
    },
    // {
    //     name: "Information og oplæring",
    //     incomplete: 3490,
    //     completed: 4300,
    // },
    {
        name: "Intern sikkerhedsrundering",
        incomplete: 3,
        completed: 4,
    },
    {
        name: "Kemikalier",
        incomplete: 0,
        completed: 1,
    },
    {
        name: "Kompressorer, varmepumper og køleanlæg",
        incomplete: 7,
        completed: 7,
    },
    {
        name: "Løftegrej",
        incomplete: 2,
        completed: 4,
    },
    {
        name: "Maskiner vedligehold",
        incomplete: 1,
        completed: 2,
    },
    {
        name: "Olietanke",
        incomplete: 2,
        completed: 5,
    },
    {
        name: "Olieudskiller",
        incomplete: 3,
        completed: 13,
    },
    {
        name: "Perkolat analyser",
        incomplete: 2,
        completed: 3,
    },
    {
        name: "Perkolat-tank/bassin",
        incomplete: 1,
        completed: 5,
    },
    {
        name: "Perkolatforbrug",
        incomplete: 1,
        completed: 33,
    },
    {
        name: "Porte",
        incomplete: 1,
        completed: 7,
    },
    {
        name: "Pumper",
        incomplete: 5,
        completed: 34,
    },
    {
        name: "Stiger",
        incomplete: 2,
        completed: 5,
    },
    {
        name: "Tæthedsprøvning",
        incomplete: 2,
        completed: 12,
    },
];

const CustomYAxisTick = ({ x, y, payload }: any) => {
    const maxLength = 20; // Maximum number of characters to display before ellipsis

    // Shorten label and add ellipsis if it's too long
    const truncatedLabel =
        payload.value.length > maxLength
            ? `${payload.value.slice(0, maxLength)}...`
            : payload.value;

    return (
        <AntTooltip title={payload.value} placement="right">
            <g transform={`translate(${x},${y})`}>
                <title>{payload.value}</title> {/* Tooltip showing full label */}
                <text x={0} y={0} dy={4} textAnchor="end" fill="#333" fontSize={12}>
                    {truncatedLabel}
                </text>
            </g>
        </AntTooltip>
    );
};

export default function StackedChart({ audits }: { audits: any[] }) {
    const dataGraph = transformDataForHeatmap(audits);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={500}
                data={dataGraph}
                layout="vertical"
                barCategoryGap={"50%"} // Space between groups of bars
                barGap={2}
                margin={{
                    top: 20,
                    right: 30,
                    left: 0,
                    bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis
                    type="category"
                    dataKey="name"
                    interval={0}
                    width={150}
                    tick={<CustomYAxisTick />}
                />
                <Tooltip />
                <Legend />
                <Bar
                    barSize={10}
                    name="Afsluttede opgaver"
                    dataKey="completed"
                    stackId="a"
                    fill={StyleGuide.palette.statusSuccessDark}
                />
                <Bar
                    name="Overskredne opgaver"
                    dataKey="incomplete"
                    stackId="a"
                    fill={StyleGuide.palette.statusErrorDark}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

type Output = {
    name: string;
    incomplete: number;
    completed: number;
};

export type Input = {
    auditId: string;
    templateTitle: string;
    auditTemplateId: string;
    needsResolution: string | null;
    hasOnHoldTasks: string | null;
    groupId: string;
    isCompleted: string;
    completeTime: string | null;
    dueDate: string;
    orgTitle: string;
    orgId: string;
    subGroup: string;
};

function transformDataForHeatmap(audits: Input[] = []): Output[] {
    // for each auditTemplateId, count the number of completed and incomplete tasks
    const counts: Record<string, Output> = {};

    audits.forEach(audit => {
        const { auditTemplateId, isCompleted } = audit;
        if (!counts[auditTemplateId]) {
            counts[auditTemplateId] = {
                name: mapData[auditTemplateId as keyof typeof mapData],
                completed: 0,
                incomplete: 0,
            };
        }
        if (isCompleted === "true") {
            counts[auditTemplateId].completed += 1;
        } else {
            counts[auditTemplateId].incomplete += 1;
        }
    });

    return Object.values(counts);
}
