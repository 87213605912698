import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { useDispatch } from "react-redux";
import { setAuditTemplateCategories as actionSetAuditTemplateCategories } from "../auditTemplates/actions";
import keyBy from "lodash.keyby";

/**
 * These are categories pulled based on the groupId the org is part of (if any)
 * Usage: to group audit tasks and templates into categories for querying and statistics
 */

export interface AuditTemplateCategories {
    id: string;
    name: string;
    groupId: string;
    createTime: Date;
    updateTime: Date;
}

export default function useAuditTemplateCategories({
    orgId,
    groupIds,
}: {
    orgId?: string;
    groupIds?: string[];
}) {
    const dispatch = useDispatch();
    const [loading, setloading] = useState(true);
    const [auditTemplateCategories, setAuditTemplateCategories] = useState<
        AuditTemplateCategories[]
    >([]);
    console.log({ auditTemplateCategories, groupIds });

    useEffect(() => {
        if (!orgId || !groupIds) return;
        setloading(true);
        let unsubscribe: any = null;
        unsubscribe = firebase
            .firestore()
            .collection(`auditTemplateCategories`)
            .where("groupId", "array-contains-any", groupIds)
            .onSnapshot(
                query => {
                    if (!query.empty) {
                        const categories: AuditTemplateCategories[] = query.docs.map(
                            doc =>
                                ({
                                    id: doc.id,
                                    ...doc.data(),
                                } as AuditTemplateCategories),
                        );
                        setAuditTemplateCategories(
                            categories.sort((a, b) => a.name.localeCompare(b.name)),
                        );
                        dispatch(
                            actionSetAuditTemplateCategories({
                                // @ts-ignore (keyBy type is )
                                payload: keyBy(categories, "id"),
                            }),
                        );
                    } else {
                        setAuditTemplateCategories([]);
                    }
                    setloading(false);
                },
                error => {
                    console.error(error);
                },
            );
        return unsubscribe;
    }, [orgId, groupIds?.length]);

    return { loading, auditTemplateCategories };
}

// Lagt ind på for RGS vest
// const templateCategories = [
//     // Kontrol plan
//     { id: "RGS_" + uuid.v4(), name: "Pumper" },
//     { id: "RGS_" + uuid.v4(), name: "Tæthedsprøvning" },
//     { id: "RGS_" + uuid.v4(), name: "Alarmer" },
//     { id: "RGS_" + uuid.v4(), name: "Olietanke" },
//     { id: "RGS_" + uuid.v4(), name: "Belægningkontrol" },
//     { id: "RGS_" + uuid.v4(), name: "Olieudskiller" },
//     { id: "RGS_" + uuid.v4(), name: "Brønde, drænbrønde og sandfang" },
//     { id: "RGS_" + uuid.v4(), name: "Maskiner vedligehold" },
//     { id: "RGS_" + uuid.v4(), name: "Porte" },
//     { id: "RGS_" + uuid.v4(), name: "Haller" },
//     { id: "RGS_" + uuid.v4(), name: "Betonelementer" },
//     { id: "RGS_" + uuid.v4(), name: "Perkolat-tank/bassin" },
//     { id: "RGS_" + uuid.v4(), name: "Perkolat analyser" },
//     // Sikkerhed
//     { id: "RGS_" + uuid.v4(), name: "Stiger" },
//     { id: "RGS_" + uuid.v4(), name: "Elværktøj & kabler" },
//     { id: "RGS_" + uuid.v4(), name: "Løftegrej" },
//     { id: "RGS_" + uuid.v4(), name: "Kemikalier" },
//     { id: "RGS_" + uuid.v4(), name: "Kompressorer, varmepumper og køleanlæg" },
//     { id: "RGS_" + uuid.v4(), name: "Førstehjælpsudstyr" },
//     { id: "RGS_" + uuid.v4(), name: "Brandudstyr" },
//     { id: "RGS_" + uuid.v4(), name: "Beredskabplan" },
//     { id: "RGS_" + uuid.v4(), name: "Intern sikkerhedsrundering" },
//     { id: "RGS_" + uuid.v4(), name: "Information og oplæring" },
//     // Miljømål
//     { id: "RGS_" + uuid.v4(), name: "Perkolatforbrug" },
//     { id: "RGS_" + uuid.v4(), name: "Elforbrug" },
//     { id: "RGS_" + uuid.v4(), name: "Dieselforbrug" },
// ];
