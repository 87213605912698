import { isAfter, isBefore } from "date-fns";
import { StyleGuide } from "styles/StyleGuide";

const categoryMap = {
    "RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a": "Løftegrej",

    "RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598": "Belægningkontrol",

    "RGS_19657e7c-7973-494d-95c5-152bdc109891": "Perkolat-tank/bassin",

    "RGS_39efaf42-bfce-4d02-973b-822c962a8fe6": "Beredskabplan",

    "RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291": "Tæthedsprøvning",

    "RGS_4df9d75b-8d1e-4544-a362-ed223da7d260": "Elforbrug",

    "RGS_554528db-3333-441a-b6c0-bad17557d370": "Perkolat analyser",

    "RGS_5c05b284-5573-4f40-9c74-84939a6a20b4": "Alarmer",

    "RGS_5f73bfe1-36fd-4cb6-a7cb-83d787161a62": "Information og oplæring",

    "RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e": "Førstehjælpsudstyr",

    "RGS_7955dd62-403f-47d4-8722-eb9729af9399": "Olieudskiller",

    "RGS_808d208a-8799-4979-ab01-a7d2d2470672": "Pumper",

    "RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5": "Brandudstyr",

    "RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00": "Maskiner vedligehold",

    "RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6": "Brønde, drænbrønde og sandfang",

    "RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259": "Kompressorer, varmepumper og køleanlæg",

    "RGS_bacb90dc-7e4a-4277-b566-591e55bb4844": "Porte",

    "RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4": "Stiger",

    "RGS_c84cb65a-9230-4982-8d64-c03351aa793f": "Perkolatforbrug",

    "RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4": "Haller",

    "RGS_cdf5e221-aa55-452a-b640-319584a41eae": "Betonelementer",

    "RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6": "Dieselforbrug",

    "RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02": "Intern sikkerhedsrundering",

    "RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121": "Olietanke",

    "RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489": "Kemikalier",

    "RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04": "Elværktøj & kabler",
};

type AuditData = {
    groupId: string;
    subGroup: string;
    orgTitle: string;
    auditTemplateId: string;
    isCompleted: string;
    dueDate: string;
};

export function prepareNestedTreeMapData(audits: AuditData[]) {
    const hierarchyMap: {
        [groupId: string]: {
            [subGroup: string]: { [auditTemplateId: string]: number };
            // };
        };
    } = {};

    // Aggregate data based on the nested hierarchy
    audits.forEach(({ groupId, subGroup, /* orgTitle*/ auditTemplateId }) => {
        if (!hierarchyMap[groupId]) {
            hierarchyMap[groupId] = {};
        }
        if (!hierarchyMap[groupId][subGroup]) {
            hierarchyMap[groupId][subGroup] = {};
        }
        // if (!hierarchyMap[groupId][orgTitle]) {
        //     hierarchyMap[groupId][orgTitle] = {};
        // }
        if (!hierarchyMap[groupId][subGroup][auditTemplateId]) {
            hierarchyMap[groupId][subGroup][auditTemplateId] = 0;
        }

        hierarchyMap[groupId][subGroup][auditTemplateId] += 1;
    });

    console.log({ hierarchyMap });

    // Convert the hierarchyMap into Nivo's hierarchical format
    return {
        name: "Audits",
        children: Object.entries(hierarchyMap).map(([groupName, subGroups]) => ({
            name: groupName,
            children: Object.entries(subGroups).map(([subGroupName, categories]) => ({
                name: subGroupName,
                // children: Object.entries(orgs).map(([orgTitle, categories]) => ({
                //     name: orgTitle,
                children: Object.entries(categories).map(([auditTemplateId, count]) => ({
                    // @ts-ignore
                    name: (categoryMap[auditTemplateId]?.slice(0, 10) as string) + "...",
                    value: count,
                })),
                // })),
            })),
        })),
    };
}

export function preparePieChartData(audits: AuditData[]) {
    const completedCount = audits.filter(audit => audit.isCompleted === "true").length;
    const todoCount = audits.filter(
        audit =>
            audit.isCompleted !== "true" &&
            isAfter(new Date(Number(audit.dueDate) * 1000), Date.now()),
    ).length;
    const incompleteCount = audits.filter(
        a =>
            a.isCompleted !== "true" &&
            isBefore(new Date(Number(a.dueDate) * 1000), Date.now()),
    ).length;

    return [
        {
            id: "Afsluttede",
            label: "Afsluttede",
            value: completedCount,
            color: StyleGuide.palette.statusSuccessDark,
        },
        {
            id: "Overskredne",
            label: "Overskredne",
            value: incompleteCount,
            color: StyleGuide.palette.statusErrorDark,
        },
        {
            id: "Fremtidige",
            label: "Fremtidige",
            value: todoCount,
            color: StyleGuide.palette.statusInprogressLight,
        },
    ];
}

export function transformAuditDataForHeatmap(audits: AuditData[]) {
    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const auditors = Array.from(new Set(audits.map(audit => audit.auditTemplateId)));

    const dataByDayAndAuditor = audits.reduce((acc, audit) => {
        const day = days[new Date(Number(audit.dueDate) * 1000).getDay()];
        acc[day] = acc[day] || {};
        acc[day][audit.auditTemplateId] = (acc[day][audit.auditTemplateId] || 0) + 1;
        return acc;
    }, {} as Record<string, Record<string, number>>);
    return [
        {
            id: "Japan",
            data: [
                {
                    x: "Train",
                    y: 16636,
                },
            ],
        },
    ];
    return days.map(day => ({
        id: day,
        data: {
            x: 1,
            y: day,
        },
        // ...Object.fromEntries(
        //     auditors.map(auditBy => [auditBy, dataByDayAndAuditor[day]?.[auditBy] || 0]),
        // ),
    }));
}
